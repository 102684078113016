// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The PaletteSlider component provides a UI for adjusting the color mapping range used in visualizing data.
 * Users can modify the minimum and maximum color values through a `GradientSlider`.
 */

import { useCacheStore } from "../../utils/store";
import { GradientSlider } from "./GradientSlider";

export const PaletteSlider = () => {
    const minColor = useCacheStore(state => state.minColor);
    const maxColor = useCacheStore(state => state.maxColor);
    const setMinColor = useCacheStore(state => state.setMinColor);
    const setMaxColor = useCacheStore(state => state.setMaxColor);

    const gradientStyle = `linear-gradient(to right, 
      #000000 0%,
      #0000ff 20%,
      #00ffff 40%,
      #00ff00 60%,
      #ffff00 80%,
      #ff0000 100%
    )`;

    const handleResetColor = () => {
        setMinColor(0);
        setMaxColor(1);
    };

    return <>
        <GradientSlider
            title="Palette"
            start={0}
            end={1}
            bgColor={gradientStyle}
            minValue={minColor}
            setMinValue={setMinColor}
            maxValue={maxColor}
            setMaxValue={setMaxColor}
            reset={handleResetColor}
        />
    </>;
};