// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The TaggingStatusSelector component provides a dropdown for selecting the tagging status 
 * of a spectrum data. It allows users to filter and manage tagging progress.
 * Supports three main tagging statuses: `NEW`, `WIP` (Work In Progress), and `DONE`.
 */

import React from "react";
import "./TaggingStatusSelector.css";

type TaggingStatusSelectorProps = {
    status: FilterTaggingStatus;
    onChange: (status: React.ChangeEvent<HTMLSelectElement>) => void;
    showNone?: boolean;
}

export const TaggingStatusSelector = ({ status, onChange, showNone=false }: TaggingStatusSelectorProps) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <select
                className="status-selector"
                value={status}
                onChange={onChange}
            >
                {showNone && <option value="ALL">ALL</option>}
                <option value="NEW">NEW</option>
                <option value="WIP">WIP</option>
                <option value="DONE">DONE</option>
            </select>
        </div>
    );
};
