// Copyright (C) 2025 Xtremis, All rights reservedisTreeViewOpen

/**
 * The SelectedVoxelInfo component displays detailed information about the currently selected voxel. 
 * Shows calculated statistics (frequency range, time range, max/avg power, and sum power).
 * Allows users to add/edit a comment field for selected voxels when in editing mode.
 * Supports both ground truth (GT) and measurement (MEA) voxel types, differentiating them accordingly.
 * Uses `calculateBoundingBoxStatistics` to compute voxel statistics based on selection.
 */

import React, { useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { calculateBoundingBoxStatistics } from "../utils/utils";
import "./SelectedVoxelInfo.css";


export const SelectedVoxelInfo = () => {
    const [selectedVoxelStats, setSelectedVoxelStats] = React.useState<VoxelStats | null>(null);
    const [info, setInfo] = React.useState<BoundingBox | null>(null);
    const [isGt, setIsGt] = React.useState<boolean>(false);

    const editingMode = useCacheStore(state => state.editingMode);
    const voxels = useCacheStore(state => state.voxels);
    const meaVoxels = useCacheStore(state => state.meaVoxels);
    const tempHighlightBox = useCacheStore(state => state.tempHighlightBox);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const updateVoxel = useCacheStore(state => state.updateVoxel);
    const persistVoxels = useCacheStore(state => state.persistVoxels);


    useEffect(() => {
        if (!heatmapData) return;

        if (tempHighlightBox) {
            setInfo(null);
            const stats = calculateBoundingBoxStatistics(
                tempHighlightBox.start,
                tempHighlightBox.end,
                tempHighlightBox.type,
                heatmapData,
                selectedAntenna,
                selectedAggregation
            );
            setSelectedVoxelStats(stats);
        } else {
            let selectedVoxel = voxels.find((v) => v.selected) ?? null;
            if (selectedVoxel === null) {
                selectedVoxel = meaVoxels.find((v) => v.selected) ?? null;
                setIsGt(false);
            } else {
                setIsGt(true);
            }
            if (selectedVoxel) {
                setInfo(selectedVoxel);
                const stats = calculateBoundingBoxStatistics(
                    selectedVoxel.start,
                    selectedVoxel.end,
                    selectedVoxel.type,
                    heatmapData,
                    selectedAntenna,
                    selectedAggregation
                );
                setSelectedVoxelStats(stats);
            } else {
                setInfo(null);
                setSelectedVoxelStats(null);
            }
        }
    }, [editingMode, voxels, tempHighlightBox, heatmapData, selectedAntenna, selectedAggregation, meaVoxels]);

    if (!selectedVoxelStats) return null;

    function updateText(event: React.ChangeEvent<HTMLInputElement>) {
        if (!info) return;
        updateVoxel({ ...info, comment: event.target.value });
        persistVoxels();
    }

    return (
        <div className="selected-voxel-info">
            {info && !tempHighlightBox && (
                <div>
                    <h4>Selected Voxel Info</h4>
                    <p>Modulation: {info.modulation}</p>
                    <p>Emitter id: {info.emitter_id}</p>
                    <p>Emitter type: {info.emitter_type}</p>
                    <p>Receiver power dbm: [{info.receive_power_dbm.join()}]</p>
                    <p>Name: {info.name}</p>
                    <p>Nr. subvoxels: {info.subvoxels?.length}</p>
                    <p>Confidence: {info.confidence}</p>
                    <p>
                        Comment:&nbsp;
                        {editingMode && isGt ? (
                            <input className={"comment-input"} value={info.comment || ""} onChange={updateText} />
                        ) : (
                            info.comment || ""
                        )}
                    </p>
                    <br />
                </div>
            )}
            <h4>Selected Voxel Stats</h4>
            <p>Freq Range: {selectedVoxelStats.freqRange}</p>
            <p>Center frequency: {selectedVoxelStats.freqCenter}</p>
            <p>Bandwidth: {selectedVoxelStats.bandwidth}</p>
            <p>Time Range: {selectedVoxelStats.timeRange}</p>
            <p>Duration: {selectedVoxelStats.duration}</p>
            <p>Power Max: {selectedVoxelStats.max} dBm</p>
            <p>Power Avg: {selectedVoxelStats.avg} dBm</p>
            <p>Power SUM: {selectedVoxelStats.sum} dBm</p>
        </div>
    );
};
