// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The GradientSlider component provides a range slider with a customizable gradient background.
 * It allows users to set a minimum and maximum value within a specified range.
 * Displays tooltips for precise value indication. Includes a reset button to restore default values.
 */

import "./GradientSlider.css";
import React, { RefObject, useRef, useState } from "react";
import { GrPowerReset } from "react-icons/gr";

type GradientSliderProps = {
    title: string;
    start: number;
    end: number;
    bgColor: string;
    minValue: number;
    setMinValue: (value: number) => void;
    maxValue: number;
    setMaxValue: (value: number) => void;
    reset: () => void;
    onMouseFinish?: () => void;
};

export const GradientSlider: React.FC<GradientSliderProps> = ({
    title,
    start,
    end,
    bgColor,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    reset,
    onMouseFinish = undefined
}) => {
    const rangeSliderRef = useRef<HTMLDivElement | null>(null);
    const [isDraggingMin, setIsDraggingMin] = useState(false);
    const [isDraggingMax, setIsDraggingMax] = useState(false);

    const slideValue = (sliderRef: RefObject<HTMLDivElement>, e: React.MouseEvent, range: number): number => {
        if (!sliderRef.current) return 0;

        const rect = sliderRef.current.getBoundingClientRect();
        const sliderWidth = rect.width;
        const offsetX = Math.max(0, Math.min(e.clientX - rect.left, sliderWidth));
        return (offsetX / sliderWidth) * range;
    };

    return (
        <div className={"gradient-slide-container"}>
            <h3 className={"cp-main-text"}>{title}</h3>
            <div className={"gradient-slider-box"}>
                <div
                    ref={rangeSliderRef}
                    className={"gradient-slider"}
                    style={{ background: bgColor }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <div
                        className="gradient-slider-tick"
                        style={{ left: `${((minValue - start) / (end - start)) * 100}%` }}
                        onMouseDown={() => {
                            const onMouseMove = (event: MouseEvent) => {
                                const value = slideValue(
                                    rangeSliderRef,
                                    event as unknown as React.MouseEvent,
                                    end - start
                                );
                                setMinValue(Math.min(Math.max(start + value, start), maxValue));
                            };
                            const onMouseUp = async () => {
                                if (onMouseFinish) onMouseFinish();
                                document.removeEventListener("mousemove", onMouseMove);
                                document.removeEventListener("mouseup", onMouseUp);
                            };
                            document.addEventListener("mousemove", onMouseMove);
                            document.addEventListener("mouseup", onMouseUp);
                        }}
                        onMouseEnter={() => {
                            setIsDraggingMin(true);
                        }}
                        onMouseLeave={() => {
                            setIsDraggingMin(false);
                        }}
                    >
                        <div className={`tooltip ${isDraggingMin ? "visible" : ""}`}>{minValue.toFixed(2)}</div>
                    </div>
                    <div
                        className="gradient-slider-tick"
                        style={{ left: `${((maxValue - start) / (end - start)) * 100}%` }}
                        onMouseDown={() => {
                            const onMouseMove = (event: MouseEvent) => {
                                const value = slideValue(
                                    rangeSliderRef,
                                    event as unknown as React.MouseEvent,
                                    end - start
                                );
                                setMaxValue(Math.max(Math.min(start + value, end), minValue));
                            };
                            const onMouseUp = async () => {
                                if (onMouseFinish) onMouseFinish();
                                document.removeEventListener("mousemove", onMouseMove);
                                document.removeEventListener("mouseup", onMouseUp);
                            };
                            document.addEventListener("mousemove", onMouseMove);
                            document.addEventListener("mouseup", onMouseUp);
                        }}
                        onMouseEnter={() => {
                            setIsDraggingMax(true);
                        }}
                        onMouseLeave={() => {
                            setIsDraggingMax(false);
                        }}
                    >
                        <div className={`tooltip ${isDraggingMax ? "visible" : ""}`}>{maxValue.toFixed(2)}</div>
                    </div>
                </div>
                <GrPowerReset className="resetButton" onClick={reset} />
            </div>
        </div>
    );
};
