// Copyright (C) 2024 Xtremis, All rights reservedisTreeViewOpen

/**
 * The PSDDiagram component visualizes Power Spectral Density (PSD) data in a dynamically rendered canvas. 
 * Aggregates and plots average (avgData) and maximum (maxData) power levels.
 * Uses a color gradient to represent power levels based on minDb and maxDb thresholds.
 *
 * The component automatically updates when the heatmap data, zoom level, or selected antenna changes.
 */

import React, { useRef, useEffect, useState } from "react";
import {
    hertz_divider,
    hertz_unit,
    PSD_HEIGHT,
    MIN_BIN_WIDTH,
    MAIN_CANVAS_VERTICAL_AXIS_SIZE,
    MAIN_CANVAS_HORIZONTAL_AXIS_SIZE
} from "../utils/constants";
import { useCacheStore } from "../utils/store";
import { formatAsHHMMSS, getColorFromNormalizedValue } from "../utils/utils";
import "./PSDDiagram.css";
import {
    drawVerticalLine,
    drawHorizontalLine,
    drawHorizontalTickValues,
    drawHorizontalDotLine
} from "../utils/drawutils";

const MAX_LINE_HEIGHT = PSD_HEIGHT - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE;

const PSDDiagram: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [avgData, setAvgData] = useState<number[]>([]);
    const [maxData, setMaxData] = useState<number[]>([]);
    const [mouseYCoord, setMouseYCoord] = useState<number | null>(null);

    const heatmapData = useCacheStore((state) => state.heatmapData);
    const selectedAntenna = useCacheStore((state) => state.selectedAntenna);
    const selectedAggregation = useCacheStore((state) => state.selectedAggregation);
    const visibleArea = useCacheStore((state) => state.visibleArea);
    const hoverCoords = useCacheStore((state) => state.hoverCoords);
    const canvasSize = useCacheStore((state) => state.canvasSize);
    const minDb = useCacheStore((state) => state.minDb);
    const maxDb = useCacheStore((state) => state.maxDb);
    const minPaprDb = useCacheStore((state) => state.minPaprDb);
    const maxPaprDb = useCacheStore((state) => state.maxPaprDb);
    const offset = useCacheStore((state) => state.offset);
    const scale = useCacheStore((state) => state.scale);
    const voxels = useCacheStore((state) => state.voxels);
    const tempHighlightBox = useCacheStore((state) => state.tempHighlightBox);
    const minColor = useCacheStore((state) => state.minColor);
    const maxColor = useCacheStore((state) => state.maxColor);

    useEffect(() => {
        if (!heatmapData) return;
        setAvgData(
            Array.from(
                heatmapData.images[selectedAntenna]["avg"]!.slice(
                    Math.floor(hoverCoords.y) * heatmapData.width + Math.floor(visibleArea.fromCol),
                    Math.floor(hoverCoords.y) * heatmapData.width + Math.floor(visibleArea.toCol)
                )
            )
        );
        if (heatmapData.images[selectedAntenna]["max"]) {
            setMaxData(
                Array.from(
                    heatmapData.images[selectedAntenna]["max"]!.slice(
                        Math.floor(hoverCoords.y) * heatmapData.width + Math.floor(visibleArea.fromCol),
                        Math.floor(hoverCoords.y) * heatmapData.width + Math.floor(visibleArea.toCol)
                    )
                )
            );
        } else {
            setMaxData([]);
        }
    }, [heatmapData, selectedAggregation, selectedAntenna, visibleArea, hoverCoords]);

    const aggregateData = (data: number[], drawableWidth: number): number[] => {
        const maxBins = Math.floor(drawableWidth / MIN_BIN_WIDTH);
        if (data.length <= maxBins) {
            return data; // No need to aggregate if data fits within the bins
        }

        const aggregatedData: number[] = [];
        const groupSize = Math.ceil(data.length / maxBins);

        for (let i = 0; i < data.length; i += groupSize) {
            const group = data.slice(i, i + groupSize);
            aggregatedData.push(Math.max(...group));
        }

        return aggregatedData;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        if (canvas.width !== canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE) {
            canvas.width = canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE;
            canvas.height = PSD_HEIGHT;
        }

        const width = canvasSize.width + MAIN_CANVAS_VERTICAL_AXIS_SIZE;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, width, PSD_HEIGHT);

        if (!heatmapData) return;

        const axisColor = "#cccccc";
        const gridColor = "#444444";
        const avgCurveColor = "#4caf50";
        const avgFillColor = "rgba(76, 175, 80, 0.4)";
        const maxCurveColor = "#93b322";
        const labelColor = "#ffffff";

        // Draw axis lines
        ctx.strokeStyle = axisColor;
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, 0);
        ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, MAX_LINE_HEIGHT);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, MAX_LINE_HEIGHT);
        ctx.lineTo(width, MAX_LINE_HEIGHT);
        ctx.stroke();

        const drawableWidth = width - MAIN_CANVAS_VERTICAL_AXIS_SIZE;

        const colorBarWidth = 5; // Thin color bar
        // Place it just left of the main axis (padding)
        const colorBarX = MAIN_CANVAS_VERTICAL_AXIS_SIZE - colorBarWidth;

        const cRange = maxColor - minColor;
        if (selectedAggregation !== "papr") {
            for (let i = 0; i < MAX_LINE_HEIGHT; i++) {
                // fraction in [0..1]
                const fraction = i / (MAX_LINE_HEIGHT - 1);

                const dBVal = heatmapData.minValue + fraction * (heatmapData.maxValue - heatmapData.minValue);

                const norm = (dBVal - minDb) / (maxDb - minDb);
                // clamp just in case
                let clamped = Math.max(0, Math.min(1, norm));
                clamped = minColor + clamped * cRange;

                const { r, g, b } = getColorFromNormalizedValue(clamped);
                const rowY = MAX_LINE_HEIGHT - 1 - i;

                ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
                ctx.fillRect(colorBarX, rowY, colorBarWidth, 1);
            }
        }

        ctx.fillStyle = labelColor;
        ctx.font = "13px 'Source Sans 3 Variable', sans-serif";
        ctx.textAlign = "right";
        ctx.textBaseline = "top";
        ctx.fillText(
            hertz_unit,
            MAIN_CANVAS_VERTICAL_AXIS_SIZE - 20,
            MAX_LINE_HEIGHT + 10
        );

        const aggregatedAvgData = aggregateData(avgData, drawableWidth);
        const aggregatedMaxData = aggregateData(maxData, drawableWidth);

        ctx.textBaseline = "middle";

        // Vertical ticks
        const numVerticalTicks = 10;
        for (let i = 0; i < numVerticalTicks - 1; i++) {
            const tickValue =
                heatmapData.minValue + (i * (heatmapData.maxValue - heatmapData.minValue)) / (numVerticalTicks - 1);
            const y = MAX_LINE_HEIGHT - (i * MAX_LINE_HEIGHT) / (numVerticalTicks - 1);

            ctx.strokeStyle = gridColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
            ctx.lineTo(width, y);
            ctx.stroke();

            // Thick tick
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 4;
            ctx.beginPath();
            ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
            ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE - 5, y);
            ctx.stroke();

            ctx.fillText(tickValue.toFixed(1), MAIN_CANVAS_VERTICAL_AXIS_SIZE - 10, y);
        }

        // Add dB label at top-left of vertical axis
        ctx.fillStyle = labelColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        ctx.fillText("(dBm)", MAIN_CANVAS_VERTICAL_AXIS_SIZE - 31, MAIN_CANVAS_HORIZONTAL_AXIS_SIZE - 40);

        ctx.textAlign = "center";
        ctx.textBaseline = "top";

        drawHorizontalTickValues(
            ctx,
            heatmapData,
            visibleArea,
            canvasSize.width,
            MAX_LINE_HEIGHT + 10,
            true
        );

        const binWidth = drawableWidth / aggregatedAvgData.length;
        ctx.strokeStyle = avgCurveColor;
        aggregatedAvgData.forEach((value, index) => {
            const x = MAIN_CANVAS_VERTICAL_AXIS_SIZE + index * binWidth + binWidth / 2;
            const binHeight =
                ((value - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * MAX_LINE_HEIGHT;
            const clampedBinHeight = Math.max(0, binHeight);

            if (index === 0) {
                ctx.moveTo(x, MAX_LINE_HEIGHT - clampedBinHeight);
            } else {
                ctx.lineTo(x, MAX_LINE_HEIGHT - clampedBinHeight);
            }
        });
        ctx.lineTo(
            MAIN_CANVAS_VERTICAL_AXIS_SIZE + (aggregatedAvgData.length - 1) * binWidth,
            MAX_LINE_HEIGHT
        );
        ctx.stroke();
        ctx.fillStyle = avgFillColor;
        ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, MAX_LINE_HEIGHT);
        ctx.closePath();
        ctx.fill();

        // Draw maxData line
        ctx.strokeStyle = maxCurveColor;
        ctx.lineWidth = 2;
        ctx.beginPath();
        aggregatedMaxData.forEach((value, index) => {
            const x = MAIN_CANVAS_VERTICAL_AXIS_SIZE + index * binWidth + binWidth / 2;
            const y =
                PSD_HEIGHT -
                MAIN_CANVAS_HORIZONTAL_AXIS_SIZE -
                ((value - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * MAX_LINE_HEIGHT;

            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.stroke();

        // If hoverCoordinates is within bounds, compute and show the time in the top-right corner
        if (
            hoverCoords.y >= 0 &&
            hoverCoords.y < heatmapData.height &&
            hoverCoords.x >= 0 &&
            hoverCoords.x < heatmapData.width
        ) {
            const hideHour =
                new Date(heatmapData.start_time * 1000).getHours() === new Date(heatmapData.end_time * 1000).getHours();
            const hideMinute =
                new Date(heatmapData.start_time * 1000).getMinutes() ===
                new Date(heatmapData.end_time * 1000).getMinutes();
            const time =
                heatmapData.end_time -
                (hoverCoords.y / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);
            const freq =
                heatmapData.start_freq +
                (hoverCoords.x / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);
            ctx.fillStyle = labelColor;
            ctx.font = "15px 'Source Sans 3 Variable', sans-serif";
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.fillText(
                "Cross: " +
                    formatAsHHMMSS(Number(time.toFixed(1)), hideHour, hideMinute) +
                    ", " +
                    (freq / hertz_divider).toFixed(1) +
                    " " +
                    hertz_unit,
                MAIN_CANVAS_VERTICAL_AXIS_SIZE + (width - MAIN_CANVAS_VERTICAL_AXIS_SIZE) / 2,
                10
            );
        }

        const x = hoverCoords.x * scale.x + offset.x + MAIN_CANVAS_VERTICAL_AXIS_SIZE;
        drawVerticalLine(ctx, x, 0, MAX_LINE_HEIGHT);

        const minDbY =
            PSD_HEIGHT -
            MAIN_CANVAS_HORIZONTAL_AXIS_SIZE -
            ((minDb - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * MAX_LINE_HEIGHT;
        const maxDbY =
            PSD_HEIGHT -
            MAIN_CANVAS_HORIZONTAL_AXIS_SIZE -
            ((maxDb - heatmapData.minValue) / (heatmapData.maxValue - heatmapData.minValue)) * MAX_LINE_HEIGHT;

        if (selectedAggregation !== "papr") {
            drawHorizontalDotLine(ctx, minDbY, MAIN_CANVAS_VERTICAL_AXIS_SIZE, width, `white`, 1.8);
            drawHorizontalDotLine(ctx, maxDbY, MAIN_CANVAS_VERTICAL_AXIS_SIZE, width, `white`, 1.8);
        }

        if (mouseYCoord) {
            if (mouseYCoord > MAX_LINE_HEIGHT) return;
            drawHorizontalLine(
                ctx,
                mouseYCoord,
                MAIN_CANVAS_HORIZONTAL_AXIS_SIZE,
                width - MAIN_CANVAS_HORIZONTAL_AXIS_SIZE
            );

            const mouseXPercentage = mouseYCoord / MAX_LINE_HEIGHT;

            const mappedValue = heatmapData.minValue + (1 - mouseXPercentage) * (heatmapData.maxValue - heatmapData.minValue);

            ctx.fillText(mappedValue.toFixed(1), MAIN_CANVAS_VERTICAL_AXIS_SIZE + 20, mouseYCoord);
        }
    }, [
        avgData,
        maxData,
        heatmapData,
        visibleArea,
        canvasSize,
        minDb,
        maxDb,
        voxels,
        tempHighlightBox,
        scale,
        offset,
        hoverCoords,
        minColor,
        maxColor,
        mouseYCoord,
        maxPaprDb,
        minPaprDb,
        selectedAggregation
    ]);

    return (
        <canvas
            ref={canvasRef}
            className="psd-diagram"
            onMouseLeave={() => {
                setMouseYCoord(null);
            }}
            onMouseMove={(e) => {
                const rect = canvasRef.current?.getBoundingClientRect();
                if (!rect) return;
                setMouseYCoord(e.clientY - rect.top);
            }}
        />
    );
};
export default PSDDiagram;
