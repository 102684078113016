/* Copyright (C) 2024 Xtremis, All rights reserved*/

/**
 * The MainCanvas component renders and manages the primary visualization of heatmap data, allowing users to 
 * interact with and manipulate the voxels, dBm, palette and score.
 * Supports user interactions including:
 *   - Zooming and panning with mouse wheel and right-click drag.
 *   - Drawing, resizing voxels.
 *   - Hovering to display frequency, time, and power information.
 * Implements automatic scrolling when the cursor moves outside the canvas while drawing.
 * Handles undo operations and user confirmation for small bounding boxes.
 */

import React, { useRef, useState, useEffect, useCallback } from "react";
import {
    calculateBoundingBoxStatistics,
    calculateMousePosition,
    calculateVisibleArea,
    correctedBoundingBox,
    createBluishImage,
    imageDataToBlob,
    isWithinImageBound,
    normalizeData, scaleTranslateBB,
    sideToHoverCorner,
    updateBoxByCorner
} from "../utils/utils";
import { getHeatmapCell, drawVoxels, drawCrossLines } from "../utils/drawutils";
import { useCacheStore } from "../utils/store";
import "./MainCanvas.css";
import { handleWheel } from "./MainCanvasHandlers";
import { ALMOST_ZERO, AUTO_SCROLL_SPEED, DEFAULT_VOXEL_INFO } from "../utils/constants";

interface CanvasProps {
    imageData: Int8Array;
    imageWidth: number;
    imageHeight: number;
}

const hoverThreshold = 5;

const MainCanvas: React.FC<CanvasProps> = ({ imageData, imageWidth, imageHeight }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isAutoScollAnimationOn = useRef<number | null>(null);
    const mousePosition = useRef({ x: 0, y: 0 });
    const lastMouseMoveTime = useRef<number>(0);
    const scheduledExecution = useRef<NodeJS.Timeout | null>(null);

    const isPanning = useRef(false);
    const panStart = useRef({ x: 0, y: 0 });

    const [showConfirmBox, setShowConfirmBox] = useState(false);

    const pendingBox = useCacheStore((state) => state.pendingBox);
    const setPendingBox = useCacheStore((state) => state.setPendingBox);
    const [filteredMeaVoxels, setFilteredMeaVoxels] = useState<BoundingBox[]>([]);

    const heatmapData = useCacheStore((state) => state.heatmapData);
    const hoverCoords = useCacheStore((state) => state.hoverCoords);
    const setHoverCoords = useCacheStore((state) => state.setHoverCoords);
    const setVisibleArea = useCacheStore((state) => state.setVisibleArea);
    const canvasSize = useCacheStore((state) => state.canvasSize);
    const setCanvasSize = useCacheStore((state) => state.setCanvasSize);
    const scale = useCacheStore((state) => state.scale);
    const offset = useCacheStore((state) => state.offset);
    const onScaleChange = useCacheStore((state) => state.setScale);
    const onOffsetChange = useCacheStore((state) => state.setOffset);
    const scaleOffsetInitialized = useCacheStore((state) => state.scaleOffsetInitialized);
    const setScaleOffsetInitialized = useCacheStore((state) => state.setScaleOffsetInitialized);
    const voxels = useCacheStore((state) => state.voxels);
    const persistVoxels = useCacheStore((state) => state.persistVoxels);
    const updateVoxel = useCacheStore((state) => state.updateVoxel);
    const getNextBoxId = useCacheStore((state) => state.getNextBoxId);
    const setVoxelSelected = useCacheStore((state) => state.setVoxelSelected);
    const deselectVoxels = useCacheStore((state) => state.deselectVoxels);
    const deleteSelectedVoxel = useCacheStore((state) => state.deleteSelectedVoxel);
    const undo = useCacheStore((state) => state.undo);
    const minDb = useCacheStore((state) => state.minDb);
    const maxDb = useCacheStore((state) => state.maxDb);
    const tempHighlightBox = useCacheStore((state) => state.tempHighlightBox);
    const setTempHighlightBox = useCacheStore((state) => state.setTempHighlightBox);
    const canvasMode = useCacheStore((state) => state.canvasMode);
    const setCanvasMode = useCacheStore((state) => state.setCanvasMode);
    const selectedAntenna = useCacheStore((state) => state.selectedAntenna);
    const selectedAggregation = useCacheStore((state) => state.selectedAggregation);
    const showVoxels = useCacheStore((state) => state.showVoxels);
    const editingMode = useCacheStore((state) => state.editingMode);
    const voxelOpacity = useCacheStore((state) => state.voxelOpacity);
    const minColor = useCacheStore((state) => state.minColor);
    const maxColor = useCacheStore((state) => state.maxColor);
    const diagonalState = useCacheStore((state) => state.diagonalState);
    const isCrossLineDrawActive = useCacheStore((state) => state.isCrossLineDrawActive);
    const setCrossLineDrawActive = useCacheStore((state) => state.setCrossLineDrawActive);
    const confidenceThreshold = useCacheStore((state) => state.confidenceSliderValue);
    const image = useCacheStore((state) => state.image);
    const setImage = useCacheStore((state) => state.setImage);
    const meaVoxels = useCacheStore((state) => state.meaVoxels);
    const setSelectedMeaVoxel = useCacheStore((state) => state.setSelectedMeaVoxel);

    useEffect(() => {
        const filteredMeaVoxels = meaVoxels.filter((v) => confidenceThreshold <= v.confidence);
        setFilteredMeaVoxels(filteredMeaVoxels);
    }, [meaVoxels, confidenceThreshold]);

    const [newBoxId, setNewBoxId] = useState<number | null>(null);

    const [hoveredBBox, setHoveredBBox] = useState<{
        position: { x: number; y: number };
        stats: VoxelStats;
    } | null>(null);

    const [hoveredCorner, setHoveredCorner] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [resizingState, setResizingState] = useState<{
        bboxId: number;
        corner: HoverCorner;
    } | null>(null);

    const [hoveredSide, setHoveredSide] = useState<{
        bboxId: number;
        side: Directions;
    } | null>(null);

    const calculateStatsAndSetHoveredBBox = useCallback(
        (bbox: BoundingBox, position: { x: number; y: number }) => {
            if (!heatmapData) return;
            if (canvasMode !== "DRAWING") return;

            const stats = calculateBoundingBoxStatistics(
                bbox.start,
                bbox.end,
                bbox.type,
                heatmapData,
                selectedAntenna,
                selectedAggregation
            );
            if (stats !== null) {
                setHoveredBBox({
                    position,
                    stats: stats
                });
            }
        },
        [canvasMode, heatmapData, selectedAggregation, selectedAntenna]
    );

    const isGT = useCallback(
        (boxId: number): boolean => {
            return voxels.find((bbox) => bbox.id === boxId) !== undefined;
        },
        [voxels]
    );

    useEffect(() => {
        let timeoutId: number | null = null;
        const canvasElement = canvasRef.current;
        if (!canvasElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout(() => {
                    if (canvasElement.width !== Math.floor(width) || canvasElement.height !== Math.floor(height)) {
                        canvasElement.width = width;
                        canvasElement.height = height;
                        setCanvasSize({ width, height });

                        const minScaleX = canvasElement.width / imageWidth;
                        const minScaleY = canvasElement.height / imageHeight;

                        const newScaleX = Math.max(scale.x, minScaleX);
                        const newScaleY = Math.max(scale.y, minScaleY);

                        onScaleChange({ x: newScaleX, y: newScaleY });
                    }
                }, 5);
            }
        });
        resizeObserver.observe(canvasElement);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            resizeObserver.disconnect();
        };
    }, [scale, imageWidth, imageHeight, onScaleChange, setCanvasSize]);

    const drawImage = useCallback(() => {
        if (!canvasRef.current || !image) return;
        if (!scale || !offset) return;

        const ctx = canvasRef.current.getContext("2d");
        if (!ctx) return;

        ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);

        const scaledWidth = image.width * scale.x;
        const scaledHeight = image.height * scale.y;
        const clampedOffsetX = Math.max(Math.min(offset.x, 0), canvasSize.width - scaledWidth);
        const clampedOffsetY = Math.max(Math.min(offset.y, 0), canvasSize.height - scaledHeight);

        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(image, clampedOffsetX, clampedOffsetY, scaledWidth, scaledHeight);

        if (showVoxels) {
            const centerY = canvasSize.height / 2;
            voxels.forEach((bbox) => {
                drawVoxels(
                    ctx,
                    bbox,
                    scale,
                    offset,
                    centerY,
                    resizingState,
                    hoveredCorner,
                    voxelOpacity,
                    diagonalState
                );
            });
            filteredMeaVoxels.forEach((bbox) => {
                drawVoxels(
                    ctx,
                    bbox,
                    scale,
                    offset,
                    centerY,
                    resizingState,
                    hoveredCorner,
                    voxelOpacity,
                    diagonalState,
                    "MEA"
                );
            });
        }

        if (!editingMode && showVoxels && tempHighlightBox) {
            const centerY = canvasSize.height / 2;
            drawVoxels(
                ctx,
                tempHighlightBox,
                scale,
                offset,
                centerY,
                resizingState,
                hoveredCorner,
                voxelOpacity,
                diagonalState
            );
        }
        drawCrossLines(ctx, image, hoverCoords, scale, clampedOffsetX, clampedOffsetY);
    }, [
        canvasRef,
        image,
        scale,
        offset,
        canvasSize,
        showVoxels,
        voxels,
        tempHighlightBox,
        editingMode,
        resizingState,
        hoveredCorner,
        hoverCoords,
        voxelOpacity,
        diagonalState,
        filteredMeaVoxels
    ]);

    useEffect(() => {
        if (resizingState && !voxels.find((bbox) => bbox.id === resizingState.bboxId)) {
            setResizingState(null);
        }

        if (hoveredCorner && !voxels.find((bbox) => bbox.id === hoveredCorner.bboxId)) {
            setHoveredCorner(null);
        }
    }, [resizingState, hoveredCorner, voxels]);

    useEffect(() => {
        if (!image || canvasSize.width === 0 || canvasSize.height === 0) return;
        if (!scaleOffsetInitialized) {
            const minScaleX = canvasSize.width / image.width;
            const minScaleY = canvasSize.height / image.height;

            const newScaleX = minScaleX;
            const newScaleY = minScaleY;

            onScaleChange({ x: newScaleX, y: newScaleY });

            const scaledWidth = image.width * newScaleX;
            const scaledHeight = image.height * newScaleY;

            const offsetX = (canvasSize.width - scaledWidth) / 2;
            const offsetY = (canvasSize.height - scaledHeight) / 2;

            onOffsetChange({ x: offsetX, y: offsetY });
            setScaleOffsetInitialized(true);
        }
    }, [
        image,
        canvasSize.width,
        canvasSize.height,
        scaleOffsetInitialized,
        onScaleChange,
        onOffsetChange,
        setScaleOffsetInitialized
    ]);

    useEffect(() => {
        async function processAndRenderImage(imageData: Int8Array, width: number, height: number) {
            const normalized = normalizeData(imageData, minDb, maxDb);
            const bluish = createBluishImage(normalized, width, height, minColor, maxColor);
            const blob = await imageDataToBlob(bluish);
            const blobUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.src = blobUrl;
            img.onload = () => {
                if (!canvasRef.current) return;
                setImage(img);
                URL.revokeObjectURL(blobUrl);
            };
        }

        processAndRenderImage(imageData, imageWidth, imageHeight).catch((error) => {
            console.error("Error processing image data:", error);
        });
    }, [imageData, imageWidth, imageHeight, minDb, maxDb, minColor, maxColor, setImage]);

    useEffect(() => {
        if (!image) return;
        if (!scale || !offset) return;

        const { visibleFromX, visibleToX, visibleFromY, visibleToY } = calculateVisibleArea(
            image.width,
            image.height,
            scale,
            offset,
            canvasSize
        );

        setVisibleArea({
            fromRow: visibleFromY,
            toRow: visibleToY,
            fromCol: visibleFromX,
            toCol: visibleToX
        });
    }, [offset, scale, image, canvasSize, setVisibleArea, drawImage]);

    useEffect(() => {
        drawImage();
    }, [
        image,
        scale,
        offset,
        newBoxId,
        voxels,
        showVoxels,
        canvasSize,
        hoverCoords,
        hoveredCorner,
        resizingState,
        tempHighlightBox,
        voxelOpacity,
        diagonalState,
        confidenceThreshold,
        filteredMeaVoxels,
        drawImage
    ]);

    useEffect(() => {
        if (!hoveredSide) return;

        const { bboxId, side } = hoveredSide;
        const bbox = filteredMeaVoxels.concat(voxels).find((bbox) => bbox.id === bboxId);
        if (!bbox) return;

        calculateStatsAndSetHoveredBBox(bbox, { x: mousePosition.current.x, y: mousePosition.current.y });

        if (canvasRef.current && editingMode && isGT(bboxId)) {
            if (side === "top" || side === "bottom") {
                canvasRef.current.style.cursor = "ns-resize";
            } else {
                canvasRef.current.style.cursor = "ew-resize";
            }
        }
    }, [hoveredSide, editingMode, filteredMeaVoxels, voxels, calculateStatsAndSetHoveredBBox, isGT]);

    useEffect(() => {
        if (!hoveredCorner) return;

        if (canvasRef.current) {
            switch (hoveredCorner.corner) {
                case "top-mid":
                case "bottom-mid":
                    if (editingMode) {
                        canvasRef.current.style.cursor = "ns-resize";
                    }
                    break;
                case "left-mid":
                case "right-mid":
                case "left":
                case "right":
                    if (editingMode) {
                        canvasRef.current.style.cursor = "ew-resize";
                    }
                    break;
                default:
                    if (editingMode) {
                        canvasRef.current.style.cursor = "nwse-resize";
                    }
                    break;
            }
        }
    }, [editingMode, hoveredCorner]);

    useEffect(() => {
        if (canvasMode !== "EMPTY") {
            let bbox: BoundingBox | undefined = undefined;

            if (tempHighlightBox) {
                bbox = tempHighlightBox;
            }

            if (newBoxId) {
                bbox = filteredMeaVoxels.concat(voxels).find((bbox) => bbox.id === newBoxId);
            }

            if (!bbox) return;

            calculateStatsAndSetHoveredBBox(bbox, {
                x: mousePosition.current.x,
                y: mousePosition.current.y
            });
        }
    }, [canvasMode, tempHighlightBox, newBoxId, filteredMeaVoxels, voxels, calculateStatsAndSetHoveredBBox]);

    const updateNewBox = useCallback(
        (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
            if (!heatmapData) return;

            const currentOffset = offsetUpdated ? offsetUpdated : offset;
            const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
            if (cell) {
                const currentVoxel = voxels.find((bbox) => bbox.id === newBoxId);
                if (currentVoxel) {
                    updateVoxel({ ...currentVoxel, end: cell });
                }
            }
        },
        [heatmapData, newBoxId, scale, voxels, offset, updateVoxel]
    );

    const updateBoxStates = useCallback(
        (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
            if (!resizingState || !heatmapData) return;

            const currentOffset = offsetUpdated ? offsetUpdated : offset;
            const { bboxId, corner } = resizingState;
            const bboxIndex = voxels.findIndex((bbox) => bbox.id === bboxId);
            if (bboxIndex === -1) {
                setResizingState(null);
                return;
            }
            const bbox = voxels[bboxIndex];

            const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
            if (cell) {
                const updatedBBox = structuredClone(bbox);
                updateBoxByCorner(corner, updatedBBox, cell);
                updateVoxel({ ...updatedBBox, selected: true });
            }
            setHoveredBBox(null);
        },
        [heatmapData, offset, resizingState, scale, voxels, updateVoxel]
    );

    const updateTempHighlightBox = useCallback(
        (mouseX: number, mouseY: number, offsetUpdated: Offset | null = null) => {
            if (!heatmapData || !tempHighlightBox) return;

            const currentOffset = offsetUpdated ? offsetUpdated : offset;
            const cell = getHeatmapCell(mouseX, mouseY, currentOffset, scale, heatmapData);
            if (cell) {
                setTempHighlightBox({ ...tempHighlightBox, end: cell });
            }
        },
        [heatmapData, offset, scale, tempHighlightBox, setTempHighlightBox]
    );

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (!scale || !offset || !heatmapData) return;

        setCanvasMode("DRAWING");

        const rect = canvasRef.current!.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        deselectVoxels();
        if (e.button === 0 && showVoxels) {
            if (hoveredCorner) {
                const { bboxId, corner } = hoveredCorner;
                setVoxelSelected(bboxId);
                if (editingMode) setResizingState({ bboxId, corner });
            } else if (hoveredSide) {
                const { bboxId, side } = hoveredSide;
                setVoxelSelected(bboxId);
                setSelectedMeaVoxel(bboxId);

                if (editingMode && isGT(bboxId)) {
                    const cornerEquivalent: HoverCorner = sideToHoverCorner(side);
                    setResizingState({ bboxId, corner: cornerEquivalent });
                }
            } else {
                deselectVoxels();
                const cell = getHeatmapCell(mouseX, mouseY, offset, scale, heatmapData);
                if (cell) {
                    if (editingMode) {
                        const newId = getNextBoxId();
                        updateVoxel({
                            ...DEFAULT_VOXEL_INFO,
                            id: newId,
                            start: cell,
                            end: cell,
                            type: "rectangle",
                            selected: true
                        });
                        setNewBoxId(newId);
                    } else {
                        setTempHighlightBox({
                            ...DEFAULT_VOXEL_INFO,
                            id: -1,
                            start: cell,
                            end: cell,
                            type: "rectangle",
                            selected: true
                        });
                    }
                }
            }
        } else if (e.button === 2) {
            isPanning.current = true;
            panStart.current = {
                x: e.clientX - offset.x,
                y: e.clientY - offset.y
            };
        }
    };

    const handleSideHover = (
        mousePos: number,
        boxPos: number,
        mouseSide: number,
        sideStart: number,
        sideEnd: number,
        direction: Directions,
        hoverThreshold: number,
        bbox: BoundingBox
    ): boolean => {
        if (
            Math.abs(mousePos - boxPos) <= hoverThreshold &&
            mouseSide > sideStart - hoverThreshold &&
            mouseSide < sideEnd + hoverThreshold
        ) {
            const hoveredSide = { bboxId: bbox.id, side: direction };
            setHoveredSide(hoveredSide);

            return true;
        }
        return false;
    };

    const handleCornerHover = (
        bbox: BoundingBox,
        mouseX: number,
        mouseY: number,
        x: number,
        y: number,
        cornerName: HoverCorner
    ): boolean => {
        if (Math.abs(mouseX - x) <= hoverThreshold && Math.abs(mouseY - y) <= hoverThreshold) {
            setHoveredCorner({ bboxId: bbox.id, corner: cornerName });
            return true;
        }
        return false;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const now = Date.now();
        const throttleDelay = 20;

        if (now - lastMouseMoveTime.current < throttleDelay) {
            if (scheduledExecution.current) {
                clearTimeout(scheduledExecution.current);
            }
            scheduledExecution.current = setTimeout(() => {
                handleMouseMove(e);
                lastMouseMoveTime.current = 0;
            }, throttleDelay + 10);

            return;
        }

        lastMouseMoveTime.current = now;

        if (canvasMode === "LONG_VOXEL_DRAWING") return;

        if (!canvasRef.current || !scale || !offset || !heatmapData || !image) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const { canvasX, canvasY, mouseX, mouseY } = calculateMousePosition(e, rect, offset, scale);

        if (isWithinImageBound(canvasX, canvasY, imageWidth, imageHeight)) {
            if (!isCrossLineDrawActive) {
                setHoverCoords({ ...hoverCoords, x: canvasX });
            } else {
                setHoverCoords({
                    x: canvasX,
                    y: canvasY
                });
            }
        }

        if (resizingState && editingMode) {
            updateBoxStates(mouseX, mouseY);
            return;
        }

        if (isPanning.current) {
            const newOffsetX = e.clientX - panStart.current.x;
            const newOffsetY = e.clientY - panStart.current.y;

            const scaledWidth = image.width * scale.x || 0;
            const scaledHeight = image.height * scale.y || 0;

            const clampedX = Math.max(Math.min(newOffsetX, 0), canvasSize.width - scaledWidth);
            const clampedY = Math.max(Math.min(newOffsetY, 0), canvasSize.height - scaledHeight);

            onOffsetChange({ x: clampedX, y: clampedY });
            setHoveredBBox(null);
            return;
        }

        if (editingMode) {
            if (newBoxId) {
                updateNewBox(mouseX, mouseY);
            }
        } else if (tempHighlightBox) {
            updateTempHighlightBox(mouseX, mouseY);
        }

        if (!showVoxels) return;

        if (canvasMode === "EMPTY") {
            let isCornerHovered = false;
            let isSideHovered = false;

            filteredMeaVoxels.concat(voxels).forEach((bbox) => {
                const isGTBox = isGT(bbox.id);
                const { start, end } = correctedBoundingBox(bbox);
                const { bboxStartX, bboxStartY, bboxEndX, bboxEndY } = scaleTranslateBB(start, end, scale, offset);

                if (bbox.type === "longVoxel") {
                    const midY = (bboxStartY + bboxEndY) / 2;
                    if (handleCornerHover(bbox, mouseX, mouseY, bboxStartX, midY, "left")) {
                        isCornerHovered = true;
                    } else if (handleCornerHover(bbox, mouseX, mouseY, bboxEndX, midY, "right")) {
                        isCornerHovered = true;
                    }

                    if (
                        handleSideHover(mouseX, bboxStartX, mouseY, bboxStartY, bboxEndY, "left", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                    if (
                        handleSideHover(mouseX, bboxEndX, mouseY, bboxStartY, bboxEndY, "right", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                } else {
                    const midX = (bboxStartX + bboxEndX) / 2;
                    const midY = (bboxStartY + bboxEndY) / 2;

                    if (isGTBox) {
                        if (handleCornerHover(bbox, mouseX, mouseY, bboxStartX, bboxStartY, "top-left")) {
                            isCornerHovered = true;
                        } else if (handleCornerHover(bbox, mouseX, mouseY, bboxEndX, bboxEndY, "bottom-right")) {
                            isCornerHovered = true;
                        } else if (handleCornerHover(bbox, mouseX, mouseY, midX, bboxStartY, "top-mid")) {
                            isCornerHovered = true;
                        } else if (handleCornerHover(bbox, mouseX, mouseY, midX, bboxEndY, "bottom-mid")) {
                            isCornerHovered = true;
                        } else if (handleCornerHover(bbox, mouseX, mouseY, bboxStartX, midY, "left-mid")) {
                            isCornerHovered = true;
                        } else if (handleCornerHover(bbox, mouseX, mouseY, bboxEndX, midY, "right-mid")) {
                            isCornerHovered = true;
                        }
                    }

                    if (
                        handleSideHover(mouseY, bboxStartY, mouseX, bboxStartX, bboxEndX, "top", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                    if (
                        handleSideHover(mouseY, bboxEndY, mouseX, bboxStartX, bboxEndX, "bottom", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                    if (
                        handleSideHover(mouseX, bboxStartX, mouseY, bboxStartY, bboxEndY, "left", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                    if (
                        handleSideHover(mouseX, bboxEndX, mouseY, bboxStartY, bboxEndY, "right", hoverThreshold, bbox)
                    ) {
                        isSideHovered = true;
                        return;
                    }
                }
            });

            if (!isCornerHovered) {
                setHoveredCorner(null);
            }
            if (!isSideHovered) {
                setHoveredSide(null);
            }

            if (!isCornerHovered && !isSideHovered) {
                setHoveredBBox(null);
                if (canvasRef.current) {
                    canvasRef.current.style.cursor = "default";
                }
            }
        }
    };

    const finishNewBox = useCallback(() => {
        const newVoxel = voxels.find((bbox) => bbox.id === newBoxId);
        if (!newVoxel) {
            setNewBoxId(null);
            return;
        }

        const { start, end } = correctedBoundingBox(newVoxel);
        newVoxel.start.col = start.col;
        newVoxel.start.row = start.row;
        newVoxel.end.col = end.col;
        newVoxel.end.row = end.row;

        const pixelWidth = Math.abs(newVoxel.end.col - newVoxel.start.col) * scale.x;
        const pixelHeight = Math.abs(newVoxel.end.row - newVoxel.start.row) * scale.y;

        if (newVoxel.start.col === newVoxel.end.col || newVoxel.start.row === newVoxel.end.row) {
            deleteSelectedVoxel();
        } else if (pixelWidth * pixelHeight < 100) {
            updateVoxel({ ...newVoxel });
            setTimeout(() => {
                setPendingBox(newVoxel);
                setShowConfirmBox(true);
            }, 50);
            setNewBoxId(null);
            return;
        } else {
            persistVoxels();
        }
        setNewBoxId(null);
    }, [voxels, newBoxId, scale, deleteSelectedVoxel, updateVoxel, persistVoxels, setPendingBox]);

    const handleConfirm = (confirmed: boolean) => {
        if (confirmed && pendingBox) {
            persistVoxels();
        } else {
            deleteSelectedVoxel();
        }
        setPendingBox(null);
        setShowConfirmBox(false);
        setNewBoxId(null);
    };

    const finishResizing = useCallback(() => {
        persistVoxels();
        setResizingState(null);
    }, [persistVoxels]);

    const handleMouseUp = useCallback(
        (button: number) => {
            if (canvasMode !== "DRAWING") return;

            setCanvasMode("EMPTY");

            if (isAutoScollAnimationOn.current) {
                cancelAnimationFrame(isAutoScollAnimationOn.current);
                isAutoScollAnimationOn.current = null;
            }

            if (button === 0) {
                if (editingMode) {
                    if (resizingState) {
                        finishResizing();
                    }

                    if (newBoxId) {
                        finishNewBox();
                    }
                } else {
                    if (tempHighlightBox) {
                        setTempHighlightBox(null);
                    }
                }
            }

            if (button === 2) {
                isPanning.current = false;
            }
        },
        [
            canvasMode,
            editingMode,
            resizingState,
            newBoxId,
            tempHighlightBox,
            setCanvasMode,
            finishResizing,
            finishNewBox,
            setTempHighlightBox
        ]
    );

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.ctrlKey || e.metaKey) {
                if (e.key === "z") {
                    if (editingMode) {
                        deselectVoxels();
                        setResizingState(null);
                        setNewBoxId(null);
                        undo();
                    } else {
                        window.alert("Undo is only available in editing mode.");
                    }
                }
                if (e.key === "q") {
                    e.preventDefault();
                    setCrossLineDrawActive(!isCrossLineDrawActive);
                }
            }
            if (e.key === "Delete" && editingMode) {
                deleteSelectedVoxel();
                setResizingState(null);
                persistVoxels();
            }
        },
        [
            editingMode,
            deselectVoxels,
            undo,
            deleteSelectedVoxel,
            isCrossLineDrawActive,
            persistVoxels,
            setCrossLineDrawActive
        ]
    );

    const animate = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const posX = mousePosition.current.x;
        const posY = mousePosition.current.y;

        const verticalDirection = (posX < 0 ? 1 : posX > canvas.width ? -1 : 0) * AUTO_SCROLL_SPEED;
        const horizontalDirection = (posY < 0 ? 1 : posY > canvas.height ? -1 : 0) * AUTO_SCROLL_SPEED;

        const newOffsetX = offset.x + verticalDirection;
        const newOffsetY = offset.y + horizontalDirection;

        const scaledWidth = imageWidth * scale.x;
        const scaledHeight = imageHeight * scale.y;

        const clampedX = Math.max(Math.min(newOffsetX, 0), canvasSize.width - scaledWidth);
        const clampedY = Math.max(Math.min(newOffsetY, 0), canvasSize.height - scaledHeight);

        const mouseX = posX <= 0 ? 0 : Math.min(canvas.width - ALMOST_ZERO, posX);
        const mouseY = posY <= 0 ? 0 : Math.min(canvas.height - ALMOST_ZERO, posY);
        const offsetUpdated = { x: clampedX, y: clampedY };

        if (editingMode) {
            if (newBoxId) {
                updateNewBox(mouseX, mouseY, offsetUpdated);
            } else if (resizingState) {
                updateBoxStates(mouseX, mouseY, offsetUpdated);
            }
        } else if (tempHighlightBox) {
            updateTempHighlightBox(mouseX, mouseY, offsetUpdated);
        }

        onOffsetChange(offsetUpdated);
        isAutoScollAnimationOn.current = requestAnimationFrame(animate);
    }, [
        canvasSize,
        editingMode,
        imageHeight,
        imageWidth,
        newBoxId,
        offset,
        resizingState,
        tempHighlightBox,
        scale,
        onOffsetChange,
        updateBoxStates,
        updateNewBox,
        updateTempHighlightBox
    ]);

    const isMouseLeftCanvas = (): boolean => {
        const canvas = canvasRef.current;
        if (!canvas) return false;

        return (
            mousePosition.current.x < 0 ||
            mousePosition.current.x > canvas.width ||
            mousePosition.current.y < 0 ||
            mousePosition.current.y > canvas.height
        );
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        const onMouseUp = (e: MouseEvent) => {
            handleMouseUp(e.button);
        };

        window.addEventListener("mouseup", onMouseUp);

        return () => {
            window.removeEventListener("mouseup", onMouseUp);
        };
    }, [handleMouseUp]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const mouseMove = (e: MouseEvent) => {
            const rect = canvas.getBoundingClientRect();
            mousePosition.current.x = e.clientX - rect.left;
            mousePosition.current.y = e.clientY - rect.top;

            if (
                !isPanning.current &&
                !isAutoScollAnimationOn.current &&
                canvasMode === "DRAWING" &&
                showVoxels &&
                isMouseLeftCanvas()
            ) {
                isAutoScollAnimationOn.current = requestAnimationFrame(animate);
            } else if (isAutoScollAnimationOn.current && canvasMode === "DRAWING" && !isMouseLeftCanvas()) {
                cancelAnimationFrame(isAutoScollAnimationOn.current);
                isAutoScollAnimationOn.current = null;
            }
        };

        if (
            !isPanning.current &&
            isAutoScollAnimationOn.current &&
            canvasMode === "DRAWING" &&
            showVoxels &&
            isMouseLeftCanvas()
        ) {
            cancelAnimationFrame(isAutoScollAnimationOn.current);
            isAutoScollAnimationOn.current = null;
            isAutoScollAnimationOn.current = requestAnimationFrame(animate);
        }

        window.addEventListener("mousemove", mouseMove);

        return () => {
            window.removeEventListener("mousemove", mouseMove);
        };
    }, [
        canvasMode,
        offset,
        editingMode,
        newBoxId,
        resizingState,
        tempHighlightBox,
        scale,
        heatmapData,
        voxels,
        animate,
        showVoxels
    ]);

    return (
        <div className="main-canvas-container" onContextMenu={(e) => e.preventDefault()}>
            <canvas
                ref={canvasRef}
                className="main-canvas"
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onWheel={(e) =>
                    handleWheel(e, scale, offset, canvasSize, imageWidth, imageHeight, onScaleChange, onOffsetChange)
                }
            />
            {hoveredBBox && !pendingBox && (
                <div
                    style={{
                        left: hoveredBBox.position.x + 10,
                        top: hoveredBBox.position.y + 10
                    }}
                    className="hovered-bbox-tooltip"
                >
                    <div>Freq: {hoveredBBox.stats.freqRange}</div>
                    <div>Time: {hoveredBBox.stats.timeRange}</div>
                    <div>Power Max: {hoveredBBox.stats.max} dBm</div>
                    <div>Power Avg: {hoveredBBox.stats.avg} dBm</div>
                    <div>Power SUM: {hoveredBBox.stats.sum} dBm</div>
                </div>
            )}
            {showConfirmBox && (
                <div className="overlay">
                    <div className="small-box-popup">
                        <p>Was the box drawing intentional? Press OK to draw it, or cancel the drawing.</p>
                        <button onClick={() => handleConfirm(true)}>OK</button>
                        <button onClick={() => handleConfirm(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default MainCanvas;
