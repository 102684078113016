// Copyright (C) 2025 Xtremis, All rights reserved

/**
 * The ConfidenceSlider component provides a user-adjustable slider for setting the confidence threshold 
 * in a range between 0 and max(1.0, measurements.map(mea => mea.confidence)).
 */

import React, { useRef, useState, useEffect, useCallback } from "react";
import { useCacheStore } from "../../utils/store";
import "./ConfidenceSlider.css";
import { GrPowerReset } from "react-icons/gr";

const ConfidenceSlider: React.FC = () => {
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const confidenceSliderValue = useCacheStore(state => state.confidenceSliderValue);
    const setConfidenceSliderValue = useCacheStore(state => state.setConfidenceSliderValue);
    const meaVoxels = useCacheStore(state => state.meaVoxels);
    const [dragging, setDragging] = useState(false);
    const [maxConfidence, setMaxConfidence] = useState<number>(1.0);
    const thumbPosition = confidenceSliderValue * 100 / maxConfidence;

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        setDragging(true);
    };

    useEffect(() => {
        setMaxConfidence(Math.max(1.0, Math.max(...meaVoxels.map(mea => mea.confidence))))
    }, [meaVoxels]);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if (!dragging || !sliderRef.current) return;
        const rect = sliderRef.current.getBoundingClientRect();
        let x = e.clientX - rect.left;
        if (x < 0) x = 0;
        if (x > rect.width) x = rect.width;
        const value = (x / rect.width);
        setConfidenceSliderValue(value * maxConfidence);
    }, [dragging, setConfidenceSliderValue, maxConfidence]);

    const handleMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [dragging, handleMouseMove]);

    return (
        <div className="confidence-slider-container">
            <h3 className={"cp-main-text"}>Score: {confidenceSliderValue.toFixed(2)}</h3>
            <div className={"confidence-slider-box"}>
                <div
                    ref={sliderRef}
                    className="confidence-slider-track"
                    onMouseDown={handleMouseDown}
                >
                    <div
                        className="confidence-slider-thumb"
                        style={{
                            left: `${thumbPosition}%`
                        }}
                    ></div>
                </div>
                <GrPowerReset className="resetButton" onClick={() => setConfidenceSliderValue(0.5)} />
            </div>
        </div>
    );
};

export default ConfidenceSlider;
