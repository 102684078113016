// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The HomeMenu component serves as the main control panel for the PSD Tagger application.
 * It provides controls for selecting aggregations, antennas. Managing visualization settings for voxels, 
 * adjusting parameters for dBm and palette, and including minimap.
 */

import "./HomeMenu.css";
import { version } from "../../utils/constants";
import DBmRangeSelector from "./DBmRangeSelector";
import ConfidenceSlider from "./ConfidenceSlider";
import Toggle from "../Toggle";
import { DiagonalSelector } from "../elements/DiagonalSelector";
import { SelectedVoxelInfo } from "../SelectedVoxelInfo";
import Minimap from "../Minimap";
import { useCacheStore } from "../../utils/store";
import { getColors } from "../../utils/apiClient";
import { PaletteSlider } from "./PaletteSlider";
import { Delimiter } from "./Delimiter";
import { MeasurementSelector } from "./MeasurementSelector";

export const HomeMenu = () => {
    const selectedAntenna = useCacheStore((state) => state.selectedAntenna);
    const setSelectedAntenna = useCacheStore((state) => state.setSelectedAntenna);
    const selectedAggregation = useCacheStore((state) => state.selectedAggregation);
    const setAggregation = useCacheStore((state) => state.setAggregation);
    const heatmapData = useCacheStore((state) => state.heatmapData);
    const selectedPath = useCacheStore((state) => state.selectedPath);
    const setMinDb = useCacheStore((state) => state.setMinDb);
    const setMaxDb = useCacheStore((state) => state.setMaxDb);
    const minPaprDb = useCacheStore((state) => state.minPaprDb);
    const maxPaprDb = useCacheStore((state) => state.maxPaprDb);
    const showVoxels = useCacheStore((state) => state.showVoxels);
    const setShowVoxels = useCacheStore((state) => state.setShowVoxels);
    const editingMode = useCacheStore((state) => state.editingMode);
    const setEditingMode = useCacheStore((state) => state.setEditingMode);
    const voxelShading = useCacheStore((state) => state.voxelOpacity);
    const setVoxelShading = useCacheStore((state) => state.setVoxelOpacity);
    const tempHighlightBox = useCacheStore((state) => state.tempHighlightBox);
    const isGTGenerated = useCacheStore((state) => state.isGTGenerated);

    const voxels = useCacheStore((state) => state.voxels);
    const meaVoxels = useCacheStore((state) => state.meaVoxels);
    let selectedVoxel = voxels.find((v) => v.selected);
    if (selectedVoxel === undefined) {
        selectedVoxel = meaVoxels.find((v) => v.selected);
    }
    const handleButtonClick = async (index: number, aggregation: string) => {
        const colors = await getColors(selectedPath, index, aggregation);
        setSelectedAntenna(index);
        setAggregation(aggregation);
        if (aggregation === "papr") {
            setMinDb(colors ? colors.minDb : minPaprDb);
            setMaxDb(colors ? colors.maxDb : maxPaprDb);
        } else {
            const mindBValue = heatmapData?.minValue ?? -120;
            const maxdBValue = heatmapData?.maxValue ?? 0;
            setMinDb(colors ? colors.minDb : mindBValue);
            setMaxDb(colors ? colors.maxDb : maxdBValue);
        }
    };

    return (
        <>
            <h2>PSD Tagger v{version}</h2>
            {heatmapData && (
                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                    <div className="scrollable-table-container">
                        <table className="button-table">
                            <tbody>
                                {Object.keys(heatmapData.images).map((index) => (
                                    <tr key={index}>
                                        <td>
                                            {heatmapData.desc.selection.receivers
                                                ? `${heatmapData.desc.selection.receivers[Number(index)].node_id} ${
                                                      heatmapData.desc.selection.receivers[Number(index)].radio_alias
                                                  }`
                                                : index}
                                        </td>
                                        {["avg", "max", "papr"].map((feature) => (
                                            <td key={feature}>
                                                <button
                                                    disabled={!heatmapData.images[index][feature]}
                                                    onClick={() => handleButtonClick(parseInt(index, 10), feature)}
                                                    className={`button ${
                                                        selectedAntenna === parseInt(index, 10) &&
                                                        selectedAggregation === feature
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                >
                                                    {feature}
                                                </button>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="toggles">
                        <div className="toggle-item">
                            <span>Edit</span>
                            <Toggle
                                state={editingMode}
                                text=""
                                setState={(state) => {
                                    if(isGTGenerated) {
                                        window.alert("Ground truth is generated. Can't edit voxels.");
                                        return;
                                    }
                                    showVoxels && setEditingMode(state);
                                }}
                            />
                        </div>

                        <div className="toggle-item">
                            <span>Show voxels</span>
                            <Toggle state={showVoxels} text="" setState={setShowVoxels} />
                        </div>

                        <div className="toggle-item">
                            <span>Shading</span>
                            <Toggle state={voxelShading} text="" setState={setVoxelShading} />
                        </div>
                    </div>

                    <DiagonalSelector />

                    <DBmRangeSelector
                        start={selectedAggregation === "papr" ? minPaprDb - 2 : heatmapData.minValue - 2}
                        end={selectedAggregation === "papr" ? maxPaprDb + 2 : heatmapData.maxValue + 2}
                    />
                    <Delimiter />
                    <MeasurementSelector />

                    <ConfidenceSlider />
                    <Delimiter />
                    <PaletteSlider />

                    <div className={`selected-voxel-info-overlay ${tempHighlightBox || selectedVoxel ? "show" : ""}`}>
                        <SelectedVoxelInfo />
                    </div>

                    <h3 className="cp-main-text">Minimap</h3>
                    <div className="minimap-container">
                        <Minimap />
                    </div>
                </div>
            )}
        </>
    );
};
