// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The MeasurementSelector component manages the selection and display of measurements.
 * It allows users to toggle measurement's and ground truth's visibility.
 */

import "./MeasurementSelector.css"

import React, { useEffect } from "react";
import { DropDown } from "./DropDown";
import { fetchVoxels } from "../../utils/utils";
import { useCacheStore } from "../../utils/store";
import Toggle from "../Toggle";

export const MeasurementSelector: React.FC = () => {
    const [showGroundTruth, setShowGroundTruth] = React.useState(true);
    const measurements = useCacheStore(state => state.measurements);
    const initVoxels = useCacheStore(state => state.initVoxels);
    const getNextBoxId = useCacheStore(state => state.getNextBoxId);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedPath = useCacheStore(state => state.selectedPath);
    const setMeaVoxels = useCacheStore(state => state.setMeaVoxels);
    const showMeasurements = useCacheStore(state => state.showMeasurements);
    const setShowMeasurements = useCacheStore(state => state.setShowMeasurements);
    const selectedMeasurement = useCacheStore(state => state.selectedMeasurement);
    const setSelectedMeasurement = useCacheStore(state => state.setSelectedMeasurement);

    const [meas, setMeas] = React.useState<{ value: string; label: string }[]>([{ value: "None", label: "None" }]);

    useEffect(() => {
        if (measurements && measurements.length > 0) {
            setMeas(measurements.map((m) => ({ value: m.name, label: m.name })));
            if(selectedMeasurement === "None")
                setSelectedMeasurement(measurements[0].name);
        }
    }, [measurements, selectedMeasurement, setSelectedMeasurement]);

    useEffect(() => {
        if (!heatmapData) {
            return;
        }
        if (showMeasurements) {
            fetchVoxels(selectedPath, selectedMeasurement, heatmapData.height, getNextBoxId).then((meas) =>
                setMeaVoxels(meas)
            );
        } else {
            setMeaVoxels([]);
        }
    }, [showMeasurements, selectedMeasurement, heatmapData, getNextBoxId, selectedPath, setMeaVoxels]);

    useEffect(() => {
        if (!heatmapData) {
            return;
        }
        if (showGroundTruth) {
            fetchVoxels(selectedPath, null, heatmapData.height, getNextBoxId).then((gt) => initVoxels(gt));
        } else {
            initVoxels([]);
        }
    }, [showGroundTruth, heatmapData, selectedPath, getNextBoxId, initVoxels]);

    return (
        <>
            <div className={"voxel-selector-header"}>
                <Toggle
                    text={"Measurements"}
                    state={showMeasurements}
                    setState={(value) => {
                        if ((measurements === null || measurements.length === 0) && value) {
                            window.alert("No measurements available");
                            return;
                        }
                        setShowMeasurements(value);
                    }}
                />
                <Toggle text={"GT"} state={showGroundTruth} setState={setShowGroundTruth} />
            </div>
            {
                <DropDown
                    options={meas}
                    onChange={setSelectedMeasurement}
                    value={selectedMeasurement!}
                    disabled={!showMeasurements}
                />
            }
        </>
    );
};
