// Copyright (C) 2024 Xtremis, All rights reserved

import { API_URL } from "./constants";

export const updateTaggingStatus = async (path: string, status: string) => {
    const response = await fetch(`${API_URL}/updateTaggingStatus`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ path: path, status: status }),
        credentials: "include"
    });
    if (!response.ok) {
        throw new Error("Failed to update tagging status");
    }
};

export const updateColors = async (path: string, receiver: number, feature: string, colors: MinMaxColors) => {
    const response = await fetch(`${API_URL}/update_colors`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ path: path, receiver: receiver, feature: feature, colors: colors }),
        credentials: "include"
    });
    if (!response.ok) {
        throw new Error("Failed to update tagging status");
    }
};

export const getColors = async (path: string, receiver: number, feature: string) :Promise<MinMaxColors> => {
    const params = new URLSearchParams();
    params.append("path", path);
    params.append("receiver", receiver.toString());
    params.append("feature", feature);

    const response = await fetch(`${API_URL}/get_colors?${params.toString()}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include"
    });
    if (!response.ok) {
        throw new Error("Failed to update tagging status");
    }
    return response.json();
};

