// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The DiagonalSelector component provides a UI for selecting different diagonal visualization modes.
 * Users can choose from the following predefined options using radio buttons:
 * - Fix: Displays the diagonal for the entire voxel area.
 * - Stick: Displays the diagonal only for the visible voxel area.
 * - None: No diagonal is displayed.
 */

import { DIAGONAL_COMBOBOX, DIAGONAL_COMBOBOX_VALUES } from "../../utils/constants";
import { useCacheStore } from "../../utils/store";
import "./DiagonalSelector.css";

import React from "react";

export const DiagonalSelector = () => {
    const diagonalState = useCacheStore((state) => state.diagonalState);
    const setDiagonalState = useCacheStore((state) => state.setDiagonalState);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as DIAGONAL_COMBOBOX;
        setDiagonalState(value);
    };

    return (
        <div className="diagonal-container">
            <h3 className={"cp-main-text"}>Diagonal</h3>
            <div className="radio-group">
                {Object.values(DIAGONAL_COMBOBOX_VALUES).map((option) => (
                    <label key={option}>
                        <input
                            type="radio"
                            name="diagonal"
                            value={option}
                            checked={diagonalState === option}
                            onChange={handleChange}
                        />
                        {option}
                    </label>
                ))}
            </div>
        </div>
    );
};
