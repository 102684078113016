// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The DropDown component provides a customizable select dropdown UI element.
 * It allows users to choose from a predefined list of options and notifies the parent component on selection.
 */

import "./DropDown.css";

import React from "react";

interface DropdownProps {
    options: { value: string; label: string }[];
    onChange?: (selectedValue: string) => void;
    value: string;
    disabled?: boolean;
}

export const DropDown: React.FC<DropdownProps> = ({options, onChange, value, disabled=false}) => {

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="dropdown-container">
            <select
                id="general-dropdown"
                value={value}
                onChange={handleChange}
                disabled={disabled}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};