// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The HorizontalAxis component renders the frequency axis for the spectrum visualization. 
 * It dynamically updates based on the visible area and heatmap data. Drawing frequency labels using `drawHorizontalTickValues`.
 * Ensures proper alignment and styling for better readability.
 */

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import { hertz_unit, HORIZONTAL_AXIS_HEIGHT } from "../utils/constants";
import { drawHorizontalTickValues } from "../utils/drawutils";

const VERTICAL_WIDTH = 60;

const HorizontalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const canvasSize = useCacheStore(state => state.canvasSize);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (!heatmapData) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        if (canvas.width !== canvasSize.width) {
            canvas.width = canvasSize.width + VERTICAL_WIDTH;
            canvas.height = HORIZONTAL_AXIS_HEIGHT;
        }

        ctx.clearRect(0, 0, canvasSize.width, HORIZONTAL_AXIS_HEIGHT);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "13px 'Source Sans 3 Variable', sans-serif";
        ctx.textBaseline = "top";
        ctx.textAlign = "left";

        ctx.fillText(hertz_unit, 0, 12);

        ctx.textAlign = "center";

        drawHorizontalTickValues(ctx, heatmapData, visibleArea, canvasSize.width, 12);
    }, [visibleArea, canvasSize.width, heatmapData]);

    return (
        <canvas
            ref={canvasRef}
            style={{
                border: "0px",
                height: `${HORIZONTAL_AXIS_HEIGHT}px`
            }}
        />
    );
};

export default HorizontalAxis;
