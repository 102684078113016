// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The InfoMenu component displays user session details (user name), provides essential information about the loaded dataset
 * including path name, measurements and modifications.
 */

import "./InfoMenu.css";

import { API_URL, version } from "../../utils/constants";
import { formatAsMDY, formatPath } from "../../utils/utils";
import React, { useEffect, useState } from "react";
import { useCacheStore } from "../../utils/store";
import { FaClipboardList } from "react-icons/fa";


export const InfoMenu = () => {
    const heatmapData = useCacheStore(state => state.heatmapData);
    const selectedPath = useCacheStore(state => state.selectedPath);
    const voxels = useCacheStore(state => state.voxels);
    const measurements = useCacheStore(state => state.measurements);
    const voxelHistory = useCacheStore(state => state.voxelHistory);
    const [username, setUsername] = useState<string>("Server without login.");
    const [modifications, setModifications] = useState<{ timestamp: number, username: string }[]>();

    const selection = heatmapData?.desc.selection;
    const scenario = heatmapData?.desc.scenario;
    useEffect(() => {
        fetch(`${API_URL}/session`, { method: "GET", credentials: "include" })
            .then(response => response.json())
            .then(data => {
                if (data.username)
                    setUsername(data.username);
            })
            .catch(error => {
                console.error("Error checking session:", error);
            });
    }, [setUsername]);

    useEffect(() => {
        const updateModifications = () => {
            const params = new URLSearchParams({ path: selectedPath });
            fetch(`${API_URL}/modifications?${params.toString()}`, { method: "GET", credentials: "include" })
                .then(response => response.json())
                .then(data => {
                    setModifications(data);
                })
                .catch(error => {
                    console.error("Error fetching modifications:", error);
                });
        };
        const timer = setTimeout(updateModifications, 300);
        return () => clearTimeout(timer);
    }, [setModifications, voxelHistory, selectedPath]);

    return (
        <>
            <h2>PSD Tagger v{version}</h2>
            <span><span className="cp-main-text">Username:</span> {username}</span>
            <p className="info-menu-top"><span className="cp-main-text">Number of voxels:</span> {voxels.length}
            </p>
            {heatmapData && selection && (
                <>
                    <span className="info-menu-top"><span
                        className="cp-main-text">Sampling rate:</span> {heatmapData.sampling_rate} Hz</span>
                    {heatmapData.start_time > 0 &&
                        <p><span className="cp-main-text">Date:</span> {formatAsMDY(heatmapData.start_time)}</p>}
                    <p className="info-menu-top">
                        <span className="cp-main-text">Selected recording:</span> <br />
                        <span style={{ whiteSpace: "pre-wrap" }}>
                            {formatPath(selectedPath || "", 35)}
                        </span>
                    </p>
                    <p className="info-menu-top">
                        <span className="cp-main-text">Frequency center:</span> {selection.center_freq_hz / 1000000} MHz
                    </p>
                    <p>
                        <span className="cp-main-text">Bandwidth:</span> {selection.bandwidth_hz / 1000000} MHz
                    </p>
                    {heatmapData.desc.selection.receivers &&
                        <p className="info-menu-top">
                            <span className="cp-main-text">Receivers:</span>
                            {heatmapData.desc.selection.receivers.map((receiver, index) => (
                                <p key={index} className="info-menu-receivers">
                                    <p><b>Node id:</b> {receiver.node_id} </p>
                                    <p><b>Radio alias:</b>{receiver.radio_alias} <br /></p>
                                </p>
                            ))}
                        </p>
                    }
                    {scenario && <>
                        <p className="cp-main-text">Scenario:</p>
                        <span className="info-menu-left"><span className="cp-main-text">Name:</span> {scenario.name ?? "N/A"}</span>
                        <span className="info-menu-left"><span className="cp-main-text">Seed:</span> {scenario.seed ?? "N/A"}</span>
                        <span className="info-menu-left"><span className="cp-main-text">Config:</span> {scenario.config ? <FaClipboardList onClick={() => window.alert(JSON.stringify(scenario.config, null, 2))}/> : "N/A"}</span>
                    </>}
                </>
            )}
            {measurements && measurements.length > 0 && (
                <>
                    <p className="cp-main-text">Measurements:</p>
                    <ul className="info-menu-left">
                        {measurements.map((measurement, index) => (
                            <li key={index}>{measurement.name}</li>
                        ))}
                    </ul>

                </>)
            }
            {modifications && modifications.length > 0 && (
                <>
                    <p className="cp-main-text">Last modifications:</p>
                    <ul className="info-menu-left">
                        {modifications.map((modification, index) => (
                            <li key={index}>{modification.username} , {new Date(modification.timestamp).toLocaleString()}</li>
                        ))}
                    </ul>
                </>)
            }
        </>
    );
};