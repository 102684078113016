// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The HelpMenu component provides an interactive help guide for users, explaining viewer and tagger functionalities.
 * It also manages session-based logout visibility. (${API_URL}/hasLogout)
 */

import React, { useEffect, useState } from "react";
import "./HelpMenu.css";
import { API_URL } from "../utils/constants";

interface HelpMenuProps {
    onClose: () => void;
    onLogout: () => void;
}

const HelpMenu: React.FC<HelpMenuProps> = ({ onClose, onLogout }) => {
    const [hasLogout, setHasLogout] = useState(false);
    useEffect(() => {
        fetch(`${API_URL}/hasLogout`, { credentials: "include" })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setHasLogout(data.hasLogout);
            })
            .catch((error) => {
                console.error("Error checking session:", error);
            });
    }, []);

    return (
        <div className="help-menu">
            <div className="info">
                <ul>
                    <li>
                        <b>Zoom: </b>Scroll with mouse
                    </li>
                    <li>
                        <b>Vertical zoom: </b>Ctrl + zoom
                    </li>
                    <li>
                        <b>Horizontal zoom: </b>Shift + zoom
                    </li>
                    <li>
                        <b>Move the image on the mark area: </b>Hold down right click and move the mouse
                    </li>
                    <li>
                        <b>Switch between images: </b>Choose either the avg the max or the papr of the receivers
                    </li>
                    <li>
                        <b>Hide or show bounding boxes: </b> Switch on “Show voxels” to see the bounding boxes. Hiding
                        them may help in identifying the exact boundaries of an area
                    </li>
                </ul>

                <ul>
                    <li>
                        <b>Table: </b>Choose among receivers and aggregation type. The selected view is dark green
                    </li>
                    <li>
                        <b>Shading: </b>Turn on to fill the internal area of the box with opaque color
                    </li>
                    <li>
                        <b>Fix diagonal: </b>Auxiliary line drawn for the real size of the box
                    </li>
                    <li>
                        <b>Stick diagonal: </b>Auxiliary line drawn for the visible area of the box, adjusting its shape
                        when zooming in or out
                    </li>
                    <li>
                        <b>dBm selection: </b>Change the range where you see the values from. The selected range is
                        bordered by the two horizontal dotted lines. Reset with the green circle
                    </li>
                    <li>
                        <b>Measurements: </b>Turn on to see the selected measurement
                    </li>
                    <li>
                        <b>GT: </b>Turn on to see the Ground Truth
                    </li>
                    <li>
                        <b>Score: </b>Adjust the score for machine generated Measurements. Reset with the green circle
                    </li>
                    <li>
                        <b>Palette: </b>Change the color range of the waterfall plot.
                    </li>
                    <li>
                        <b>Minimap: </b>Shows the selected region when zooming in. Drag the box on the minimap in order
                        to move the visible selection on the waterfall plot
                    </li>
                    <li>
                        <b>Fix the horizontal ruler: </b>CTRL+Q, Release: same shortcut
                    </li>
                </ul>
                <ul>
                    <li>
                        <b>Edit mode: </b> Switch edit mode on with the button to draw or edit bounding boxes. When Edit
                        mode is on you can draw, edit or delete bounding boxes
                    </li>
                    <li>
                        <b>Draw bounding box: </b>Hold down left click and move the mouse
                    </li>
                    <li>
                        <b>Draw long bounding box: </b>Left click and move mouse in the bar below the PSD diagram and
                        above the waterfall plot
                    </li>
                    <li>
                        <b>Select bounding box for editin: </b>Left click on the edge of the bounding box
                    </li>
                    <li>
                        <b>Delete selected bounding box: </b>Delete button on the keyboard
                    </li>
                    <li>
                        <b>Undo: </b>CTRL + Z
                    </li>
                    <li>
                        <b>Logout: </b>Click on the Logout button on the info panel
                    </li>
                </ul>
            </div>
            <div className="button-container">
                <button onClick={onClose}>Close</button>
                {hasLogout && (
                    <button onClick={onLogout} className="logout-button">
                        Logout
                    </button>
                )}
            </div>
        </div>
    );
};

export default HelpMenu;
