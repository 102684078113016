// Copyright (C) 2024 Xtremis, All rights reserved

import {
    purple,
    HALF_HANDLE_SIZE,
    HANDLE_SIZE,
    white,
    white_box_background,
    axis_cross_line_color,
    VOXEL_LINE_WIDTH,
    DIAGONAL_COMBOBOX,
    hertz_divider
} from "./constants";
import { correctedBoundingBox, formatLabel, quantizeStep, scaleTranslateBB } from "./utils";

const alphaMin = 0;
const alphaMax = 0.4;

function colorWithOpacity(color: string, alpha: number): string {
    // Simple example with two known color strings:
    // Adjust or expand for your actual color usage
    if (color === purple) {
        // purple => (128,0,128)
        return `rgba(128,0,128,${alpha})`;
    }
    if (color === white) {
        // white => (255,255,255)
        return `rgba(255,255,255,${alpha})`;
    }
    if (color === white_box_background) {
        // same as white, or differ if needed
        return `rgba(255,255,255,${alpha})`;
    }
    // fallback
    return color; // or `rgba(0,0,0,${alpha})`
}

export const getHeatmapRow = (y: number, offsetY: number, scaleY: number, heatmapDataHeight: number) => {
    const row = (y - offsetY) / scaleY;
    if (row >= 0 && row < heatmapDataHeight) {
        return row;
    }
    return null;
};

export const getHeatmapCol = (x: number, offsetX: number, scaleX: number, heatmapDataWidth: number) => {
    const col = (x - offsetX) / scaleX;
    if (col >= 0 && col < heatmapDataWidth) {
        return col;
    }
    return null;
};

export const getHeatmapCell = (
    x: number,
    y: number,
    offset: { x: number; y: number },
    scale: { x: number; y: number },
    heatmapData: { width: number; height: number }
) => {
    const col = getHeatmapCol(x, offset.x, scale.x, heatmapData.width);
    const row = getHeatmapRow(y, offset.y, scale.y, heatmapData.height);
    if (col !== null && row !== null) {
        return { col, row };
    }
    return null;
};

export const drawBoundingBox = (
    ctx: CanvasRenderingContext2D,
    start: { col: number; row: number },
    end: { col: number; row: number },
    scale: Scale,
    offset: Offset,
    color: string,
    drawing: DrawingType = "continuous",
    voxelOpacity: boolean
) => {
    const startX = Math.min(start.col, end.col) * scale.x + offset.x;
    const startY = Math.min(start.row, end.row) * scale.y + offset.y;
    const endX = Math.max(start.col, end.col) * scale.x + offset.x;
    const endY = Math.max(start.row, end.row) * scale.y + offset.y;

    if (drawing === "dashed") ctx.setLineDash([10, 5]);
    else ctx.setLineDash([]);
    ctx.strokeStyle = color;
    ctx.lineWidth = VOXEL_LINE_WIDTH;
    ctx.strokeRect(startX, startY, endX - startX, endY - startY);
    ctx.setLineDash([]);
    const alpha = voxelOpacity ? alphaMax : alphaMin;
    ctx.fillStyle = colorWithOpacity(white_box_background, alpha);
    ctx.fillRect(startX, startY, endX - startX, endY - startY);
};

export const drawDiagonal = (
    ctx: CanvasRenderingContext2D,
    color: string,
    bboxStartX: number,
    bboxStartY: number,
    bboxEndX: number,
    bboxEndY: number,
    diagonalState: DIAGONAL_COMBOBOX,
    drawing: DrawingType = "continuous"
) => {
    if (drawing === "dashed") ctx.setLineDash([10, 5]);
    else ctx.setLineDash([]);
    if (diagonalState === "Stick") {
        const canvasWidth = ctx.canvas.width;
        const canvasHeight = ctx.canvas.height;

        const visibleStartX = Math.max(bboxStartX, 0);
        const visibleStartY = Math.max(bboxStartY, 0);
        const visibleEndX = Math.min(bboxEndX, canvasWidth);
        const visibleEndY = Math.min(bboxEndY, canvasHeight);

        ctx.strokeStyle = color;
        ctx.lineWidth = VOXEL_LINE_WIDTH;
        ctx.beginPath();
        ctx.moveTo(visibleStartX, visibleStartY);
        ctx.lineTo(visibleEndX, visibleEndY);
        ctx.stroke();
    } else if (diagonalState === "Fix") {
        ctx.strokeStyle = color;
        ctx.lineWidth = VOXEL_LINE_WIDTH;
        ctx.beginPath();
        ctx.moveTo(bboxStartX, bboxStartY);
        ctx.lineTo(bboxEndX, bboxEndY);
        ctx.stroke();
    }
};

export const drawVoxels = (
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    scale: Scale,
    offset: Offset,
    centerY: number,
    resizingState: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    voxelOpacity: boolean,
    diagonalState: DIAGONAL_COMBOBOX,
    meaType: "GT" | "MEA" = "GT"
) => {
    const isResizing = resizingState && resizingState.bboxId === bbox.id;

    let color = white;
    if (bbox.selected || isResizing) {
        color = purple;
    }

    drawBoundingBox(
        ctx,
        bbox.start,
        bbox.end,
        scale,
        offset,
        color,
        meaType === "GT" ? "continuous" : "dashed",
        voxelOpacity
    );

    const { start, end } = correctedBoundingBox(bbox);
    const { bboxStartX, bboxStartY, bboxEndX, bboxEndY } = scaleTranslateBB(start, end, scale, offset);

    drawDiagonal(
        ctx,
        color,
        bboxStartX,
        bboxStartY,
        bboxEndX,
        bboxEndY,
        diagonalState,
        meaType === "GT" ? "continuous" : "dashed"
    );

    if (bbox.type === "longVoxel" && meaType === "GT") {
        const fillStyle = bbox.selected || isResizing ? purple : white;
        drawLongVoxel(
            ctx,
            fillStyle,
            centerY,
            bboxStartX,
            bboxEndX,
            voxelOpacity,
            bbox.id,
            isResizing,
            bbox.selected,
            hovewhiteCorner
        );
    } else if (bbox.type === "rectangle" && meaType === "GT") {
        drawLRectangle(
            ctx,
            isResizing,
            bbox.selected,
            bbox.id,
            bboxStartX,
            bboxStartY,
            bboxEndX,
            bboxEndY,
            hovewhiteCorner
        );
    }
};

export const drawCrossLines = (
    ctx: CanvasRenderingContext2D,
    image: HTMLImageElement,
    hoverCoords: Coords,
    scale: Scale,
    clampedOffsetX: number,
    clampedOffsetY: number
): void => {
    if (!hoverCoords) {
        return;
    }
    ctx.setLineDash([]);
    const scaledWidth = image.width * scale.x;
    if (hoverCoords.y >= 0 && hoverCoords.y < image.height) {
        const hoverY = hoverCoords.y * scale.y + clampedOffsetY;
        drawHorizontalLine(ctx, hoverY, clampedOffsetX, scaledWidth);
    }

    const scaledHeight = image.height * scale.y;
    if (hoverCoords.x >= 0 && hoverCoords.x < image.width) {
        const hoverX = hoverCoords.x * scale.x + clampedOffsetX;
        drawVerticalLine(ctx, hoverX, clampedOffsetY, scaledHeight);
    }
};

export const drawHorizontalLine = (
    ctx: CanvasRenderingContext2D,
    y: number,
    offsetX: number,
    width: number,
    color: string = axis_cross_line_color
): void => {
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(offsetX, y);
    ctx.lineTo(offsetX + width, y);
    ctx.stroke();
};

export const drawHorizontalDotLine = (
    ctx: CanvasRenderingContext2D,
    y: number,
    offsetX: number,
    width: number,
    color: string = axis_cross_line_color,
    lineWidth: number = 1
): void => {
    ctx.save();

    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.setLineDash([2, 2]);
    ctx.beginPath();
    ctx.moveTo(offsetX, y);
    ctx.lineTo(offsetX + width, y);
    ctx.stroke();

    ctx.restore();
};

export const drawVerticalLine = (ctx: CanvasRenderingContext2D, x: number, offsetY: number, height: number): void => {
    ctx.strokeStyle = axis_cross_line_color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(x, offsetY);
    ctx.lineTo(x, offsetY + height);
    ctx.stroke();
};

export const drawLongVoxel = (
    ctx: CanvasRenderingContext2D,
    fillStyle: string,
    centerY: number,
    bboxStartX: number,
    bboxEndX: number,
    voxelOpacity: boolean,
    boxId: number,
    isResizing: boolean | null,
    selected: boolean,
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null
): void => {
    const alpha = voxelOpacity ? alphaMax : alphaMin;

    if (fillStyle === "purple") {
        ctx.fillStyle = `rgba(128,0,128,${alpha})`;
    } else if (fillStyle === "white") {
        ctx.fillStyle = `rgba(255,255,255,${alpha})`;
    } else {
        // fallback
        ctx.fillStyle = fillStyle;
    }

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "left") ? purple : white;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, centerY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "right") ? purple : white;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, centerY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
};

export const drawLRectangle = (
    ctx: CanvasRenderingContext2D,
    isResizing: boolean | null,
    selected: boolean,
    boxId: number,
    bboxStartX: number,
    bboxStartY: number,
    bboxEndX: number,
    bboxEndY: number,
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null
): void => {
    // Corners
    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "top-left") ? purple : white;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "bottom-right") ? purple : white;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    // Midpoints
    const midX = (bboxStartX + bboxEndX) / 2;
    const midY = (bboxStartY + bboxEndY) / 2;

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "top-mid") ? purple : white;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxStartY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "bottom-mid") ? purple : white;
    ctx.fillRect(midX - HALF_HANDLE_SIZE, bboxEndY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "left-mid") ? purple : white;
    ctx.fillRect(bboxStartX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);

    ctx.fillStyle = isActive(hovewhiteCorner, boxId, selected, isResizing, "right-mid") ? purple : white;
    ctx.fillRect(bboxEndX - HALF_HANDLE_SIZE, midY - HALF_HANDLE_SIZE, HANDLE_SIZE, HANDLE_SIZE);
};

const isActive = (
    hovewhiteCorner: {
        bboxId: number;
        corner: HoverCorner;
    } | null,
    boxId: number,
    selected: boolean,
    isResizing: boolean | null,
    controlPointName: string
): boolean | null => {
    return (
        selected ||
        isResizing ||
        (hovewhiteCorner && hovewhiteCorner.bboxId === boxId && hovewhiteCorner.corner === controlPointName)
    );
};

export const drawHorizontalTickValues = (
    ctx: CanvasRenderingContext2D,
    heatmapData: HeatmapData,
    visibleArea: VisibleArea,
    canvasWidth: number,
    verticalAxisShifting: number,
    enableAxisLine: boolean = false
) => {
    const totalTicks = Math.floor(canvasWidth / 60);

    if (totalTicks === 0) return;

    const freqDiff = heatmapData.end_freq - heatmapData.start_freq;
    const fromConverterPercentage = visibleArea.fromCol / heatmapData.width;
    const toConverterPercentage = visibleArea.toCol / heatmapData.width;

    const visibleFreqRange =
        heatmapData.start_freq +
        toConverterPercentage * freqDiff -
        (heatmapData.start_freq + fromConverterPercentage * freqDiff);

    const tickStep = Number((quantizeStep(visibleFreqRange / totalTicks) / hertz_divider).toFixed(1));

    const startValue = (heatmapData.start_freq + fromConverterPercentage * freqDiff) / hertz_divider;
    const startValue2 = formatLabel(heatmapData.start_freq + fromConverterPercentage * freqDiff);

    let x: number = 0;
    let i: number = 0;

    if (visibleFreqRange === 0) return;
    while (x < canvasWidth + VISIBILITY_SHIFT) {
        const tickValue = Number(startValue2) + i * tickStep;
        x = ((tickValue - startValue) * hertz_divider * canvasWidth) / visibleFreqRange + VISIBILITY_SHIFT;
        if (x >= VISIBILITY_SHIFT && x < canvasWidth + VISIBILITY_SHIFT) {
            ctx.lineWidth = 1;
            if (enableAxisLine) {
                ctx.strokeStyle = gridColor;
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(x, verticalAxisShifting - 10);
                ctx.stroke();
            }

            ctx.strokeStyle = labelColor;
            ctx.beginPath();
            ctx.moveTo(x, enableAxisLine ? verticalAxisShifting - 10 : 0);
            ctx.lineTo(x, enableAxisLine ? verticalAxisShifting - 5 : 10);
            ctx.stroke();

            ctx.fillText(tickValue.toFixed(1), x, verticalAxisShifting);
        }
        i++;
    }
};

const gridColor = "#444444";
const labelColor = "#ffffff";
const VISIBILITY_SHIFT = 60;
