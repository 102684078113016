// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The DBmRangeSelector component provides an interactive slider for selecting the dBm range in a heatmap visualization.
 * It allows users to adjust the minimum and maximum dBm values, updating the visualization dynamically.
 */

import React, { useEffect, useRef, useState } from "react";
import { useCacheStore } from "../../utils/store";
import { updateColors } from "../../utils/apiClient";
import { getColorFromNormalizedValue } from "../../utils/utils";
import { GradientSlider } from "./GradientSlider";

type DBmRangeSelectorProps = {
    start: number;
    end: number;
};

const DBmRangeSelector: React.FC<DBmRangeSelectorProps> = ({ start, end }) => {
    const minDb = useCacheStore(state => state.minDb);
    const minDbRef = useRef(minDb);
    const maxDb = useCacheStore(state => state.maxDb);
    const maxDbRef = useRef(maxDb);
    const setMinDb = useCacheStore(state => state.setMinDb);
    const setMaxDb = useCacheStore(state => state.setMaxDb);
    const selectedPath = useCacheStore(state => state.selectedPath);
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);

    const minColor = useCacheStore(state => state.minColor);
    const maxColor = useCacheStore(state => state.maxColor);
    const minPaprDb = useCacheStore((state) => state.minPaprDb);
    const maxPaprDb = useCacheStore((state) => state.maxPaprDb);
    const heatmapData = useCacheStore((state) => state.heatmapData);

    const [colorGrad, setColorGrad] = useState<string>("red");

    useEffect(() => {
        minDbRef.current = minDb;
        maxDbRef.current = maxDb;
    }, [minDb, maxDb]);


    useEffect(() => {
        const stops = [];
        const steps = 100; // Increase for smoother gradient
        const { r: r1, g: g1, b: b1 } = getColorFromNormalizedValue(minColor);
        const startPos = (minDb - start) / (end - start);
        stops.push(`rgb(${r1}, ${g1}, ${b1}) ${startPos * 100}%`);

        for (let i = 0; i < steps; i++) {
            const dbValue = (i / steps) * (maxColor - minColor) + minColor;
            const { r, g, b } = getColorFromNormalizedValue(dbValue);
            const position = startPos + i / steps * (maxDb - minDb) / (end - start);
            stops.push(`rgb(${r}, ${g}, ${b}) ${position * 100}%`);
        }

        const endPos = (maxDb - start) / (end - start);
        const { r: r3, g: g3, b: b3 } = getColorFromNormalizedValue(maxColor);
        stops.push(`rgb(${r3}, ${g3}, ${b3}) ${endPos * 100}%`);
        stops.push(`rgb(${r3}, ${g3}, ${b3}) 100%`);

        const grad = `linear-gradient(to right, ${stops.join(", ")})`;
        setColorGrad(grad);
    }, [minColor, maxColor, minDb, maxDb, start, end]);

    const handleResetDb = (aggregation: string) => {
        if (aggregation === "papr") {
            setMinDb(minPaprDb);
            setMaxDb(maxPaprDb);
        } else {
            const mindBValue = heatmapData?.minValue ?? -120;
            const maxdBValue = heatmapData?.maxValue ?? 0;
            setMinDb(mindBValue);
            setMaxDb(maxdBValue);
        }
    };

    return (
        <GradientSlider
            title="dBm Selection"
            start={start}
            end={end}
            bgColor={colorGrad}
            minValue={minDb}
            setMinValue={setMinDb}
            maxValue={maxDb}
            setMaxValue={setMaxDb}
            reset={() => handleResetDb(selectedAggregation)}
            onMouseFinish={async () => {
                await updateColors(selectedPath, selectedAntenna, selectedAggregation, {
                    minDb: minDbRef.current,
                    maxDb: maxDbRef.current
                });
            }
            }
        />
    );
};

export default DBmRangeSelector;
