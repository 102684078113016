// Copyright (C) 2024 Xtremis, All rights reserved

import React from "react";
import "./Delimiter.css";

/**
 * The Delimiter component is a visual element that helps separate content with a horizontal line.
 */

export const Delimiter: React.FC = () => {
    return (
        <div className="delimiter"/>
    );
};