// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The Toggle component provides a simple switch UI for enabling or disabling a boolean state.
 * It is used to control voxel visibility or other toggled settings.
 */

import React from "react";
import "./Toggle.css";

interface VoxelToggleProps {
    text: string;
    state: boolean;
    setState: (showVoxels: boolean) => void;
}
const Toggle: React.FC<VoxelToggleProps> = ({ text, state, setState }) => {
    const handleToggle = () => {
        setState(!state);
    };

    return (
        <div className="toggle-container" onClick={handleToggle} role="button">
            {text && <h3 className="cp-main-text">{text}</h3>}
            <div className={`toggle-switch ${state ? "on" : "off"}`}>
                <div className={`toggle-circle ${state ? "on" : "off"}`}></div>
            </div>
        </div>
    );
};

export default Toggle;
