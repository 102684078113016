// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The VerticalAxis component renders a vertical time axis alongside the heatmap data visualization.
 * It dynamically updates based on the visible area, ensuring accurate time labeling.
 * Uses `quantizeStep` to determine appropriate tick intervals.
 * Formats time labels with `formatAsHHMMSS`, adapting to hour/minute visibility.
 */

import React, { useRef, useEffect } from "react";
import { useCacheStore } from "../utils/store";
import {  LONG_VOXEL_PANEL_HEIGHT, MAIN_CANVAS_VERTICAL_AXIS_SIZE } from "../utils/constants";
import "./VerticalAxis.css";
import { formatAsHHMMSS, quantizeStep } from "../utils/utils";

const VerticalAxis: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const heatmapData = useCacheStore(state => state.heatmapData);
    const visibleArea = useCacheStore(state => state.visibleArea);
    const canvasSize = useCacheStore(state => state.canvasSize);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        if (canvas.height !== canvasSize.height) {
            // we have some space above and under the "canvas"
            canvas.height = LONG_VOXEL_PANEL_HEIGHT + canvasSize.height + LONG_VOXEL_PANEL_HEIGHT;
            canvas.width = MAIN_CANVAS_VERTICAL_AXIS_SIZE;
        }

        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        if (!heatmapData) return;

        ctx.clearRect(0, 0, MAIN_CANVAS_VERTICAL_AXIS_SIZE, canvasSize.height);

        ctx.fillStyle = "#fff";
        ctx.strokeStyle = "#fff";
        ctx.font = "11px Arial";
        ctx.textAlign = "right";
        ctx.textBaseline = "top";

        const totalTicks = Math.ceil(canvasSize.height / 25);
        const fromConverterPercentage = visibleArea.fromRow / heatmapData.height;
        const toConverterPercentage = visibleArea.toRow / heatmapData.height;

        const timeDiff = heatmapData.end_time - heatmapData.start_time;
        const visibleTimeRange =
            heatmapData.start_time +
            toConverterPercentage * timeDiff -
            (heatmapData.start_time + fromConverterPercentage * timeDiff);

        const tickStep = quantizeStep(visibleTimeRange / (totalTicks ));

        const startValue = heatmapData.end_time - fromConverterPercentage * timeDiff;
        const endValue =  heatmapData.end_time - toConverterPercentage * timeDiff;

        const hideHour = new Date(startValue * 1000).getHours() === new Date(endValue * 1000).getHours();
        const hideMinute = new Date(startValue * 1000).getMinutes() === new Date(endValue * 1000).getMinutes();
        ctx.font = hideHour ? "12px 'Source Sans 3 Variable', sans-serif" : "12px 'Source Sans 3 Variable', sans-serif";
        const format = hideHour ? (hideMinute ? "ss.s" : "mm:ss.s") : "hh:mm:ss.s";
        ctx.fillText(`(${format})`, MAIN_CANVAS_VERTICAL_AXIS_SIZE, 0);
        ctx.font = hideHour ? "12px 'Source Sans 3 Variable', sans-serif" : "12px 'Source Sans 3 Variable', sans-serif";

        const rangeInverse = 1 / visibleTimeRange;

        const startValueFixed = Number(startValue.toFixed(1));
        for (let i = 0; i < totalTicks; i++) {
            const tickValue = Number((startValueFixed - (i * tickStep)).toFixed(1));
            const normalizedTick = (tickValue - endValue) * rangeInverse;
            const y = LONG_VOXEL_PANEL_HEIGHT + canvasSize.height * (1 - normalizedTick);
            if (y >= LONG_VOXEL_PANEL_HEIGHT && y <= canvasSize.height + LONG_VOXEL_PANEL_HEIGHT) {
                ctx.beginPath();
                ctx.moveTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE - 6, y);
                ctx.lineTo(MAIN_CANVAS_VERTICAL_AXIS_SIZE, y);
                ctx.stroke();

                ctx.fillText(
                    formatAsHHMMSS(tickValue, hideHour, hideMinute),
                    MAIN_CANVAS_VERTICAL_AXIS_SIZE - 8,
                    y
                );
            }
        }
    }, [heatmapData, visibleArea, canvasSize.height]);

    return (
        <canvas
            ref={canvasRef}
            className="vertical-axis-container"
            style={{
                height: `calc(100% + ${2 * LONG_VOXEL_PANEL_HEIGHT}px)`,
                marginTop: `-${LONG_VOXEL_PANEL_HEIGHT}px`
            }}
        />
    );
};

export default VerticalAxis;
