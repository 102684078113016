/* Copyright (C) 2024 Xtremis, All rights reserved*/

/**
 * Handles zooming interactions on the canvas using the mouse wheel. 
 * Supports uniform, vertical, and horizontal zooming based on key modifiers. (CTRL for vertical, SHIFT for horizontal)
 */

import React from "react";
import { MAX_ZOOM } from "../utils/constants";

export const handleWheel = (
    e: React.WheelEvent<HTMLCanvasElement>,
    scale: Scale,
    offset: Offset,
    canvasSize: CanvasSize,
    imageWidth: number,
    imageHeight: number,
    onScaleChange: (scale: Scale) => void,
    onOffsetChange: (offset: Offset) => void
) => {
    if (!scale || !offset) return;

    const { offsetX, offsetY } = e.nativeEvent;

    const minScaleX = canvasSize.width / imageWidth;
    const minScaleY = canvasSize.height / imageHeight;

    let newScaleX = scale.x;
    let newScaleY = scale.y;

    if (e.ctrlKey) {
        // Vertical zoom
        newScaleY = Math.max(Math.min(scale.y - e.deltaY * 0.001, MAX_ZOOM), minScaleY);
    } else if (e.shiftKey) {
        // Horizontal zoom
        newScaleX = Math.max(Math.min(scale.x - (e.deltaX + e.deltaY) * 0.001, MAX_ZOOM), minScaleX);
    } else {
        // Uniform zoom
        newScaleX = Math.max(Math.min(scale.x - e.deltaY * 0.001, MAX_ZOOM), minScaleX);
        newScaleY = Math.max(Math.min(scale.y - e.deltaY * 0.001, MAX_ZOOM), minScaleY);
    }

    const scaleRatioX = newScaleX / scale.x;
    const scaleRatioY = newScaleY / scale.y;

    const newOffsetX = offset.x - (offsetX - offset.x) * (scaleRatioX - 1);
    const newOffsetY = offset.y - (offsetY - offset.y) * (scaleRatioY - 1);

    onScaleChange({ x: newScaleX, y: newScaleY });
    onOffsetChange({
        x: Math.max(Math.min(newOffsetX, 0), canvasSize.width - imageWidth * newScaleX),
        y: Math.max(Math.min(newOffsetY, 0), canvasSize.height - imageHeight * newScaleY)
    });
};
