// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The ControlPanel component manages the UI for fetching and selecting spectrum data. It provides a file explorer 
 * (TreeView) that allows users to browse and select files. When a file is selected, the heat map data corresponding 
 * to the selected file is automatically downloaded and updates the current states in the common store (store.ts).
 */

import React, { useEffect, useState } from "react";
import "./ControlPanel.css";
import { fetchMatrix, fetchRestMatrix, voxelTransformator } from "../utils/utils";
import { useCacheStore } from "../utils/store";
import TreeView, { TreeNode } from "./TreeView";
import { API_URL } from "../utils/constants";
import { InfoMenu } from "./menu/InfoMenu";
import { HomeMenu } from "./menu/HomeMenu";
import { AiFillHome } from "react-icons/ai";

const ControlPanel: React.FC = () => {
    const setError = useCacheStore((state) => state.setError);
    const setLoading = useCacheStore((state) => state.setLoading);
    const setHeatmapData = useCacheStore((state) => state.setHeatmapData);
    const initVoxels = useCacheStore((state) => state.initVoxels);
    const getNextBoxId = useCacheStore((state) => state.getNextBoxId);
    const reset = useCacheStore((state) => state.reset);
    const selectedPath = useCacheStore((state) => state.selectedPath);
    const setSelectedPath = useCacheStore((state) => state.setSelectedPath);
    const loading = useCacheStore((state) => state.loading);
    const updateHeatmapData = useCacheStore((state) => state.updateHeatmapData);
    const setMinDb = useCacheStore((state) => state.setMinDb);
    const setMaxDb = useCacheStore((state) => state.setMaxDb);
    const showVoxels = useCacheStore((state) => state.showVoxels);
    const editingMode = useCacheStore((state) => state.editingMode);
    const setEditingMode = useCacheStore((state) => state.setEditingMode);
    const setMeasurements = useCacheStore((state) => state.setMeasurements);
    const deselectVoxels = useCacheStore((state) => state.deselectVoxels);
    const setIsGTGenerated = useCacheStore((state) => state.setIsGTGenerated);

    const [activePage, setActivePage] = useState<"info" | "home">("home");
    const [isTreeViewOpen, setIsTreeViewOpen] = useState<boolean>(true);
    const [files, setFiles] = useState<TreeNode[]>([]);
    const [savedEditingMode, setSavedEditingMode] = useState(editingMode);

    useEffect(() => {
        if (showVoxels) {
            setSavedEditingMode(editingMode);
        }
    }, [showVoxels, editingMode]);

    useEffect(() => {
        if (showVoxels) {
            setEditingMode(savedEditingMode);
        } else {
            setEditingMode(false);
        }
    }, [showVoxels, savedEditingMode, setEditingMode]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!selectedPath) {
            setHeatmapData(null);
            setLoading(false);
            setError("Please choose a file from the tree view");
            return;
        }

        setLoading(true);
        setError("");
        setHeatmapData(null);

        fetchMatrix(selectedPath, signal)
            .then(({ voxels, is_generated, ...data }: ResponseHeatmapData) => {
                setHeatmapData(data);
                setIsGTGenerated(is_generated);
                initVoxels(voxelTransformator(voxels, data.height, getNextBoxId));
                setMeasurements(data.measurements);
                setMinDb(data.color_settings?.minDb ?? data.minValue);
                setMaxDb(data.color_settings?.maxDb ?? data.maxValue);
                setError("");
                setLoading(false);

                fetchRestMatrix(
                    selectedPath,
                    Object.keys(data.images).length,
                    Object.keys(data.images["0"]),
                    updateHeatmapData,
                    data.width,
                    data.height,
                    signal
                ).catch((error) => {
                    console.log("Error fetching rest matrix:", error);
                });
            })
            .catch((error) => {
                setError(error.message || "Error fetching data");
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            controller.abort();
        };
    }, [
        selectedPath,
        initVoxels,
        getNextBoxId,
        setError,
        setHeatmapData,
        setLoading,
        setMinDb,
        setMaxDb,
        setMeasurements,
        updateHeatmapData
    ]);

    useEffect(() => {
        if (!isTreeViewOpen) return;
        fetch(`${API_URL}/spectrums`, { credentials: "include" })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to fetch spectrum data");
                }
            })
            .then((data: TaggingFiles[]) => {
                const transformedData = data.map(
                    (tf, index) =>
                        ({
                            id: index.toString(),
                            name: tf.path,
                            type: "file",
                            status: tf.status
                        } as TreeNode)
                );
                setFiles(transformedData);
            })
            .catch((error) => {
                console.error("Error fetching spectrum data:", error);
            });
    }, [
        isTreeViewOpen,
        initVoxels,
        getNextBoxId,
        setError,
        setHeatmapData,
        setLoading,
        setMaxDb,
        setMinDb,
        setMeasurements,
        updateHeatmapData
    ]);

    const toggleTreeView = () => {
        setIsTreeViewOpen(!isTreeViewOpen);
    };

    const handleFileSelect = (filePath: string) => {
        toggleTreeView();
        if (selectedPath === filePath) {
            return;
        }
        reset();
        setSelectedPath(filePath);
    };

    const handleOverlayClick = () => {
        if (isTreeViewOpen) {
            setIsTreeViewOpen(false);
        }
    };

    useEffect(() => {
        if (isTreeViewOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isTreeViewOpen]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                deselectVoxels();
                if (isTreeViewOpen) {
                    setIsTreeViewOpen(false);
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isTreeViewOpen, loading, deselectVoxels]);

    return (
        <div className="control-panel-container">
            {/* Hamburger menu */}
            <div className="hamburger-menu-wrapper">
                <div
                    className={`hamburger-menu-button ${isTreeViewOpen ? "active" : ""}`}
                    onClick={toggleTreeView}
                    aria-label="Toggle File Explorer"
                    tabIndex={0}
                    onKeyUp={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                            toggleTreeView();
                        }
                    }}
                >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

            {!isTreeViewOpen && (
                <div className="side-icons">
                    {/* Data Tagger Icon */}
                    <button
                        className={`tab-button ${activePage === "home" ? "active" : ""}`}
                        onClick={() => setActivePage("home")}
                        title="Home"
                    >
                        <AiFillHome size={20} />
                    </button>

                    <button
                        className={`tab-button ${activePage === "info" ? "active" : ""}`}
                        onClick={() => setActivePage("info")}
                        title="Info"
                    >
                        Info
                    </button>
                </div>
            )}

            {/* Container for side panel + main content */}
            <div className={`control-panel-inside-container ${isTreeViewOpen ? "open" : ""}`}>
                {/* Side panel */}
                <div className={`side-panel ${isTreeViewOpen ? "open" : ""}`}>
                    {/* Recording Explorer */}
                    <p className="side-panel-title">Recording Explorer</p>
                    <TreeView data={files} onFileSelect={handleFileSelect} />
                </div>

                {/* Main content area */}
                <div className="control-panel-inside">
                    {activePage === "info" && <InfoMenu />}

                    {activePage === "home" && <HomeMenu />}
                </div>
            </div>

            {isTreeViewOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
        </div>
    );
};

export default ControlPanel;
