// Copyright (C) 2024 Xtremis, All rights reserved

import { ALMOST_ZERO, API_URL, hertz_divider, hertz_unit } from "./constants";
import React from "react";

interface ColorStop {
    value: number;
    r: number;
    g: number;
    b: number;
}

//Viridis like color palette
/*const colorStops: ColorStop[] = [
    { value: 0.0, color: 0x2860a2 }, // Blue
    { value: 0.33, color: 0x18a060 }, // Greenish
    { value: 0.66, color: 0x19a25d }, // Similar to above
    { value: 1.0, color: 0x08d827 }, // Bright Green
];*/

//Modified rainbow
const colorStops: ColorStop[] = [
    { value: 0.0, r: 0, g: 0, b: 0 }, // Black at minimum
    { value: 0.2, r: 0, g: 0, b: 255 }, // Blue
    { value: 0.4, r: 0, g: 255, b: 255 }, // Cyan
    { value: 0.6, r: 0, g: 255, b: 0 }, // Green
    { value: 0.8, r: 255, g: 255, b: 0 }, // Yellow
    { value: 1.0, r: 255, g: 0, b: 0 } // Red at maximum
];

export function formatPath(path: string, maxLength: number): string {
    const regex = new RegExp(`.{1,${maxLength}}`, "g");
    const segments = path.match(regex) || [path];
    return segments.join("\n");
}

export function normalizeData(data: Int8Array, minDb: number, maxDb: number): Float32Array {
    const range = maxDb - minDb;
    const normalizedData = new Float32Array(data.length);

    for (let i = 0; i < data.length; i++) {
        const normalized = (data[i] - minDb) / range;
        normalizedData[i] = Math.min(Math.max(normalized, 0), 1); // Clamp between 0 and 1
    }

    return normalizedData;
}

export const getColorFromNormalizedValue = (normalizedValue: number): RGB => {
    let startStop = colorStops[colorStops.length - 2];
    let endStop = colorStops[colorStops.length - 1];

    for (let i = 0; i < colorStops.length - 1; i++) {
        if (normalizedValue >= colorStops[i].value && normalizedValue <= colorStops[i + 1].value) {
            startStop = colorStops[i];
            endStop = colorStops[i + 1];
            break;
        }
    }

    const range = endStop.value - startStop.value;
    const factor = (normalizedValue - startStop.value) / range;

    const startColor = { r: startStop.r, g: startStop.g, b: startStop.b };
    const endColor = { r: endStop.r, g: endStop.g, b: endStop.b };
    return {
        r: Math.round(startColor.r + factor * (endColor.r - startColor.r)),
        g: Math.round(startColor.g + factor * (endColor.g - startColor.g)),
        b: Math.round(startColor.b + factor * (endColor.b - startColor.b))
    };
};

export function createBluishImage(data: Float32Array, width: number, height: number, minColor: number, maxColor: number): ImageData {
    const imageData = new ImageData(width, height);
    const cRange = maxColor - minColor;
    for (let i = 0; i < data.length; i++) {
        const value = minColor + data[i] * cRange;
        const { r, g, b } = getColorFromNormalizedValue(value);

        const idx = i * 4;
        imageData.data[idx] = r;
        imageData.data[idx + 1] = g;
        imageData.data[idx + 2] = b;
        imageData.data[idx + 3] = 255;
    }
    return imageData;
}

export function imageDataToBlob(imageData: ImageData): Promise<Blob> {
    const canvas = document.createElement("canvas");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        throw new Error("Could not get 2D context from canvas");
    }
    ctx.putImageData(imageData, 0, 0);

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                console.log("Failed to create Blob from canvas");
            }
        }, "image/png");
    });
}

const reverseRows = (data: Int8Array, width: number, height: number): Int8Array => {
    const reversedData = new Int8Array(data.length);
    for (let row = 0; row < height; row++) {
        for (let col = 0; col < width; col++) {
            reversedData[(height - row - 1) * width + col] = data[row * width + col];
        }
    }
    return reversedData;
};

export const fetchMatrix = async (path: string, signal: AbortSignal): Promise<ResponseHeatmapData> => {
    const params = new URLSearchParams({ path });
    const data = await fetch(`${API_URL}/info?${params.toString()}`, { credentials: "include", signal });
    const info: {
        height: number;
        width: number;
        features: string[];
        receivers: number;
        voxels: Voxels;
        minValue: number;
        maxValue: number;
        start_freq: number;
        end_freq: number;
        sampling_rate: number;
        measurements: Measurements;
        color_settings: { minDb: number; maxDb: number };
        desc: Desc,
        is_generated: boolean
    } = await data.json();

    const response: ResponseHeatmapData = {
        width: info.width,
        height: info.height,
        voxels: info.voxels,
        minValue: info.minValue,
        maxValue: info.maxValue,
        start_time: info.desc.selection.start_time_s,
        end_time: info.desc.selection.end_time_s,
        start_freq: info.start_freq,
        end_freq: info.end_freq,
        images: {},
        sampling_rate: info.sampling_rate,
        measurements: info.measurements,
        color_settings: info.color_settings,
        desc: info.desc,
        is_generated: info.is_generated
    };

    for (let i = 0; i < info.receivers; i++) {
        if (!response.images[i]) {
            response.images[i] = {};
        }
        for (const feature of info.features) {
            response.images[i][feature] = null;
        }
        response.images[i]["papr"] = null;
    }
    const urlParams = new URLSearchParams({ path, receiver: "0", feature: info.features[0] });
    const img = await fetch(`${API_URL}/image?${urlParams.toString()}`, { credentials: "include" });
    response.images[0][info.features[0]] = reverseRows(new Int8Array(await img.arrayBuffer()), info.width, info.height);
    return response;
};

export const fetchRestMatrix = async (
    path: string,
    receivers: number,
    features: string[],
    updateHeatmapData: (array: Int8Array, receiver: number, aggregation: string) => void,
    width: number,
    height: number,
    signal: AbortSignal
) => {
    for (let i = 0; i < receivers; i++) {
        for (const feature of features) {
            if (i === 0 && feature === features[0]) continue;
            if (feature === "papr") {
                continue;
            }
            const urlParams = new URLSearchParams({ path, receiver: i.toString(), feature: feature });
            const img = await fetch(`${API_URL}/image?${urlParams.toString()}`, { credentials: "include", signal });
            updateHeatmapData(reverseRows(new Int8Array(await img.arrayBuffer()), width, height), i, feature);
        }
    }
};

export const sideToHoverCorner = (side: Directions): HoverCorner => {
    let cornerEquivalent: HoverCorner;
    switch (side) {
        case "top":
            cornerEquivalent = "top-mid";
            break;
        case "bottom":
            cornerEquivalent = "bottom-mid";
            break;
        case "left":
            cornerEquivalent = "left-mid";
            break;
        case "right":
            cornerEquivalent = "right-mid";
            break;
    }

    return cornerEquivalent;
};

export const updateBoxByCorner = (
    corner: HoverCorner,
    updatedBBox: BoundingBox,
    cell: { col: number; row: number }
): void => {
    switch (corner) {
        case "left":
        case "left-mid":
            updatedBBox.start.col = cell.col;
            break;
        case "right":
        case "right-mid":
            updatedBBox.end.col = cell.col;
            break;
        case "top-mid":
            updatedBBox.start.row =cell.row;
            break;
        case "bottom-mid":
            updatedBBox.end.row = cell.row;
            break;
        case "top-left":
            updatedBBox.start = cell;
            break;
        case "bottom-right":
            updatedBBox.end = cell;
            break;
    }
};

export const calculateMousePosition = (
    e: React.MouseEvent<HTMLCanvasElement>,
    rect: DOMRect,
    offset: Offset,
    scale: Scale
) => {
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    return {
        canvasX: (mouseX - offset.x) / scale.x,
        canvasY: (mouseY - offset.y) / scale.y,
        mouseX,
        mouseY
    };
};

export const isWithinImageBound = (canvasX: number, canvasY: number, imageW: number, imageH: number): boolean => {
    return canvasX >= 0 && canvasX < imageW && canvasY >= 0 && canvasY < imageH;
};

export function formatAsHHMMSS(unixSeconds: number, hideHour: boolean = true, hideMinute: boolean = false) {
    let resString = "";
    const date = new Date(unixSeconds * 1000); // multiply by 1000 to convert seconds → ms
    if (!hideHour) {
        const hh = String(date.getHours()).padStart(2, "0");
        resString += `${hh}:`;
    }
    if (!hideMinute) {
        const mm = String(date.getMinutes()).padStart(2, "0");
        resString += `${mm}:`;
    }
    const ss = String(date.getSeconds()).padStart(2, "0");
    resString += `${ss}.`;
    const ms = String(date.getMilliseconds()).padStart(1, "0").charAt(0);
    resString += `${ms}`;
    return resString;
}

export function formatAsMDY(unixSeconds: number) {
    const date = new Date(unixSeconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

function formatAdaptive(value: number, decimals = 2): string {
    const absValue = Math.abs(value);

    if (absValue >= 1.0e9) {
        return (value / 1.0e9).toFixed(decimals) + "G";
    } else if (absValue >= 1.0e6) {
        return (value / 1.0e6).toFixed(decimals) + "M";
    } else if (absValue >= 1.0e3) {
        return (value / 1.0e3).toFixed(decimals) + "k";
    } else {
        // Less than 1000, just show the number normally
        return value.toFixed(decimals);
    }
}

export const calculateBoundingBoxStatistics = (
    start: { col: number; row: number },
    end: { col: number; row: number },
    voxelType: string,
    heatmapData: HeatmapData,
    selectedAntenna: number,
    selectedAggregation: string
) => {
    const fromCol = Math.min(start.col, end.col);
    const toCol = Math.max(start.col, end.col);
    const fromRow =Math.min(start.row, end.row);
    const toRow = Math.max(start.row, end.row);
    
    const freqRangeStart =
        heatmapData.start_freq + (fromCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);
    const freqRangeEnd =
        heatmapData.start_freq + (toCol / heatmapData.width) * (heatmapData.end_freq - heatmapData.start_freq);

    let timeRangeStart =
        heatmapData.end_time - ((toRow) / heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);
    let timeRangeEnd =
        heatmapData.end_time - (fromRow/ heatmapData.height) * (heatmapData.end_time - heatmapData.start_time);

    if (start.row === 0 && (end.row === heatmapData.height || end.row === heatmapData.height - 1)) {
        timeRangeStart = heatmapData.start_time;
        timeRangeEnd = heatmapData.end_time;
    }

    let sum = 0;
    let max = -Infinity;
    let count = 0;

    const data = heatmapData.images[selectedAntenna][selectedAggregation];
    if (!data) return null;

    const tr = Math.floor(toRow) + (voxelType === "longVoxel" ? 0 : 1);
    const tc = Math.floor(toCol) + (voxelType === "longVoxel" ? 0 : 1);

    for (let row = Math.floor(fromRow); row < tr; row++) {
        for (let col = Math.floor(fromCol); col < tc; col++) {
            const index = row * heatmapData.width + col;
            const value = data[index];
            sum += value;
            if (value > max) max = value;
            count++;
        }
    }
    const avg = sum / count;

    const hideHour = new Date(heatmapData.start_time * 1000).getHours() === new Date(heatmapData.end_time * 1000).getHours();
    const hideMinute = new Date(heatmapData.start_time  * 1000).getMinutes() === new Date(heatmapData.end_time * 1000).getMinutes();

    return {
        freqRange: `${(freqRangeStart / hertz_divider).toFixed(2)} ${hertz_unit} - ${(freqRangeEnd / hertz_divider).toFixed(2)} ${hertz_unit}`,
        timeRange: `${formatAsHHMMSS(Number(timeRangeStart.toFixed(1)), hideHour, hideMinute)} - ${formatAsHHMMSS(Number(timeRangeEnd.toFixed(1)), hideHour, hideMinute)}`,
        avg: formatAdaptive(avg, 3),
        max: formatAdaptive(max, 3),
        sum: formatAdaptive(sum, 3),
        freqCenter: `${((freqRangeStart + freqRangeEnd) / 2 / hertz_divider).toFixed(2)} ${hertz_unit}`,
        bandwidth: `${((freqRangeEnd - freqRangeStart) / hertz_divider).toFixed(3)} ${hertz_unit}`,
        duration: `${(timeRangeEnd - timeRangeStart - (voxelType === "longVoxel" ? 1 : 0)).toFixed(2)} s`
    };
};

export function calculateVisibleArea(
    imageWidth: number,
    imageHeight: number,
    scale: Scale,
    offset: Offset,
    canvasSize: CanvasSize
) {
    const visibleFromX = Math.max(0, -offset.x / scale.x);
    const visibleToX = Math.min(imageWidth, (-offset.x + canvasSize.width) / scale.x);

    const visibleFromY = Math.max(0, -offset.y / scale.y);
    const visibleToY = Math.min(imageHeight, (-offset.y + canvasSize.height) / scale.y);
    return { visibleFromX, visibleToX, visibleFromY, visibleToY };
}

export function calcMousePosToCanvas(
    clientX: number,
    clientY: number,
    rect: DOMRect,
    canvasWidth: number,
    canvasHeight: number,
    heatmapData: HeatmapData
) {
    const mouseX = ((clientX - rect.left) * canvasWidth) / rect.width;
    const mouseY = ((clientY - rect.top) * canvasHeight) / rect.height;

    const scaleX = canvasWidth / heatmapData.width;
    const scaleY = canvasHeight / heatmapData.height;
    return { mouseX, mouseY, scaleX, scaleY };
}

export const voxelTransformator = (
    voxel: Voxel[],
    height: number,
    getNextBoxId: () => number,
): BoundingBox[] => {
    const boxes: BoundingBox[] = [];
    for (let i = 0; i < voxel.length; i++) {
        const id = getNextBoxId();
        const longVoxel = Math.abs(voxel[i].startRow) < ALMOST_ZERO && Math.abs(voxel[i].endRow - height) < ALMOST_ZERO;
        const { startCol, endCol, startRow, endRow, ...data } = voxel[i];
        boxes.push({
            id,
            intervalId: 0,
            start: { col: startCol, row: height - endRow },
            end: { col: endCol, row: height - startRow },
            type: longVoxel ? "longVoxel" : "rectangle",
            selected: false,
            ...data
        });
    }
    return boxes;
};

export const fetchVoxels = (path: string, measurement: string | null, mtxHeight: number, nextId: (() => number)): Promise<BoundingBox[]> => {
    const params = new URLSearchParams({ path: path });
    if (measurement)
        params.append("measurement_name", measurement);
    return fetch(`${API_URL}/tag_info/measurement?${params.toString()}`, { credentials: "include" })
        .then(res => res.json())
        .then(data => {
            return voxelTransformator(data, mtxHeight, nextId);
        })
        .catch(() => {
            window.alert("Error fetching measurement data");
            return [];
        });
};

export const quantizeStep = (step: number): number => {
    const STEP_DIVIDER = 10;
    return Math.ceil(step * STEP_DIVIDER) / STEP_DIVIDER;
};

export const formatLabel = (value: number): string => {
    return `${(value / hertz_divider).toFixed(1)} `;
};

export const correctedBoundingBox = (
    bbox: BoundingBox
): { start: { col: number; row: number }; end: { col: number; row: number } } => {
    const startCol = Math.min(bbox.start.col, bbox.end.col);
    const startRow = Math.min(bbox.start.row, bbox.end.row);
    const endCol = Math.max(bbox.start.col, bbox.end.col);
    const endRow = Math.max(bbox.start.row, bbox.end.row);

    return { start: { col: startCol, row: startRow }, end: { col: endCol, row: endRow } };
};

export function scaleTranslateBB(
    start: { col: number; row: number },
    end: { col: number; row: number },
    scale: Scale,
    offset: Offset
): { bboxStartX: number; bboxStartY: number; bboxEndX: number; bboxEndY: number } {
    const bboxStartX = start.col * scale.x + offset.x;
    const bboxStartY = start.row * scale.y + offset.y;
    const bboxEndX = end.col * scale.x + offset.x;
    const bboxEndY = end.row * scale.y + offset.y;

    return { bboxStartX, bboxStartY, bboxEndX, bboxEndY };
}
