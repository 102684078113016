// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The LongVoxelPanel component provides an interactive canvas for drawing and managing long voxels within 
 * the spectrum visualization. It allows users to creat, and highlight long voxels.
 * Uses throttled event listeners to optimize performance during mouse movements.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    LONG_VOXEL_PANEL_HEIGHT,
    DEFAULT_VOXEL_INFO,
    MAIN_CANVAS_VERTICAL_AXIS_SIZE,
    purple,
    ALMOST_ZERO,
    VOXEL_LINE_WIDTH
} from "../utils/constants";
import { useCacheStore } from "../utils/store";
import { getHeatmapCol } from "../utils/drawutils";

const LongVoxelPanel: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const lastMouseMoveTime = useRef<number>(0);
    const scheduledExecution = useRef<NodeJS.Timeout | null>(null);

    const [startX, setStartX] = useState<number | null>(null);
    const [newBB, setBB] = useState<BoundingBox | null>(null);

    const scale = useCacheStore((state) => state.scale);
    const offset = useCacheStore((state) => state.offset);
    const hoverCoords = useCacheStore((state) => state.hoverCoords);
    const deselectVoxels = useCacheStore((state) => state.deselectVoxels);
    const showVoxels = useCacheStore((state) => state.showVoxels);
    const heatmapData = useCacheStore((state) => state.heatmapData);
    const editingMode = useCacheStore((state) => state.editingMode);
    const canvasSize = useCacheStore((state) => state.canvasSize);
    const setTempHighlightBox = useCacheStore((state) => state.setTempHighlightBox);
    const canvasMode = useCacheStore((state) => state.canvasMode);
    const setCanvasMode = useCacheStore((state) => state.setCanvasMode);
    const getNextBoxId = useCacheStore((state) => state.getNextBoxId);
    const updateVoxel = useCacheStore((state) => state.updateVoxel);
    const persistVoxels = useCacheStore((state) => state.persistVoxels);
    const setHoverCoords = useCacheStore((state) => state.setHoverCoords);
    const isCrossLineDrawActive = useCacheStore((state) => state.isCrossLineDrawActive);

    const isDrawingCanvas = canvasMode === "DRAWING";

    const redrawCanvas = useCallback(() => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            if (!ctx) return;

            ctx.fillStyle = "grey";
            ctx.fillRect(0, 0, canvasSize.width, LONG_VOXEL_PANEL_HEIGHT);
        }
    }, [canvasSize]);

    const drawLongVoxelHeader = useCallback(
        (mouseX: number, startX: number) => {
            redrawCanvas();
            if (canvasRef.current) {
                const ctx = canvasRef.current.getContext("2d");
                if (!ctx) return;

                if (startX === null || mouseX === null) return;

                ctx.fillStyle = purple;
                const width = Math.abs(mouseX - startX) < VOXEL_LINE_WIDTH ? VOXEL_LINE_WIDTH : mouseX - startX;
                ctx.fillRect(startX, 0, width, LONG_VOXEL_PANEL_HEIGHT);
            }
        },
        [redrawCanvas]
    );

    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.width = canvasSize.width;
            canvasRef.current.height = LONG_VOXEL_PANEL_HEIGHT;

            redrawCanvas();
        }
    }, [canvasSize, redrawCanvas]);

    const handleMouseDown = useCallback(
        (e: React.MouseEvent<HTMLCanvasElement>) => {
            if (!showVoxels || isDrawingCanvas || !heatmapData) return; // If we don't show voxels, skip
            setCanvasMode("LONG_VOXEL_DRAWING");

            const rect = canvasRef.current!.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            setStartX(mouseX);

            deselectVoxels();

            const col = getHeatmapCol(mouseX, offset.x, scale.x, heatmapData.width);
            if (col === null) return;

            if (editingMode) {
                const newId = getNextBoxId();
                const newBB: BoundingBox = {
                    ...DEFAULT_VOXEL_INFO,
                    id: newId,
                    start: { row: 0, col: col },
                    end: { row: heatmapData.height - ALMOST_ZERO, col: col },
                    type: "longVoxel",
                    selected: true
                };

                updateVoxel(newBB);
                setBB(newBB);
            } else {
                setTempHighlightBox({
                    ...DEFAULT_VOXEL_INFO,
                    id: -1,
                    start: { row: 0, col: col },
                    end: { row: heatmapData.height - ALMOST_ZERO, col: col },
                    type: "longVoxel",
                    selected: true
                });
            }

            drawLongVoxelHeader(mouseX, mouseX);
        },
        [
            heatmapData,
            editingMode,
            showVoxels,
            isDrawingCanvas,
            offset,
            scale,
            deselectVoxels,
            drawLongVoxelHeader,
            getNextBoxId,
            setCanvasMode,
            setTempHighlightBox,
            updateVoxel
        ]
    );

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            const now = Date.now();
            const throttleDelay = 20;

            if (now - lastMouseMoveTime.current < throttleDelay) {
                if (scheduledExecution.current) {
                    clearTimeout(scheduledExecution.current);
                }
                scheduledExecution.current = setTimeout(() => {
                    handleMouseMove(e);
                    lastMouseMoveTime.current = 0;
                }, throttleDelay + 10);

                return;
            }

            lastMouseMoveTime.current = now;

            if (!canvasRef.current || !heatmapData) return;
            if (e.target !== canvasRef.current && canvasMode !== "LONG_VOXEL_DRAWING") return;

            const rect = canvasRef.current.getBoundingClientRect();

            const mouseX = Math.max(0, Math.min(rect.width - 1, e.clientX - rect.left));
            const canvasX = (mouseX - offset.x) / scale.x;
            const canvasY = -offset.y / scale.y;
            const adjustCanvasX = Math.max(0, Math.min(heatmapData.width, canvasX));

            if (mouseX >= 0 && mouseX <= rect.width) {
                if (!isCrossLineDrawActive) {
                    setHoverCoords({ ...hoverCoords, x: adjustCanvasX });
                } else {
                    setHoverCoords({ x: adjustCanvasX, y: canvasY });
                }

                if (startX === null) return;
                const startCol = getHeatmapCol(startX, offset.x, scale.x, heatmapData.width);
                const endCol = getHeatmapCol(mouseX, offset.x, scale.x, heatmapData.width);

                if (startCol === null || endCol === null) return;
                if (canvasMode !== "LONG_VOXEL_DRAWING") return;

                if (editingMode) {
                    if (!newBB) return;
                    const startColMin = Math.min(startCol, endCol);
                    const endColMax = Math.max(startCol, endCol);

                    const newVoxel: BoundingBox = {
                        ...newBB,
                        start: { row: 0, col: startColMin },
                        end: { row: heatmapData.height - ALMOST_ZERO, col: endColMax }
                    };

                    updateVoxel(newVoxel);
                    setBB(newVoxel);
                } else {
                    setTempHighlightBox({
                        ...DEFAULT_VOXEL_INFO,
                        id: -1,
                        start: { row: 0, col: startCol },
                        end: { row: heatmapData.height - ALMOST_ZERO, col: endCol },
                        type: "longVoxel",
                        selected: true
                    });
                }

                drawLongVoxelHeader(mouseX, startX);
            }
        },
        [
            canvasMode,
            editingMode,
            offset,
            scale,
            heatmapData,
            startX,
            newBB,
            isCrossLineDrawActive,
            hoverCoords,
            drawLongVoxelHeader,
            setHoverCoords,
            setTempHighlightBox,
            updateVoxel
        ]
    );

    const handleMouseUp = useCallback(() => {
        if (canvasMode !== "LONG_VOXEL_DRAWING") return;
        setCanvasMode("EMPTY");

        if (editingMode) {
            if (!newBB) return;
            updateVoxel({
                ...newBB
            });
            setBB(null);
            persistVoxels();
        } else {
            setTempHighlightBox(null);
        }

        redrawCanvas();
    }, [canvasMode, editingMode, newBB, persistVoxels, redrawCanvas, setCanvasMode, setTempHighlightBox, updateVoxel]);

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseMove]);

    useEffect(() => {
        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [handleMouseUp]);

    return (
        <canvas
            ref={canvasRef}
            className="longvoxel-panel"
            style={{
                width: `calc(100% - ${MAIN_CANVAS_VERTICAL_AXIS_SIZE}px)`,
                height: `${LONG_VOXEL_PANEL_HEIGHT}px`,
                marginLeft: `${MAIN_CANVAS_VERTICAL_AXIS_SIZE}px`
            }}
            onMouseDown={handleMouseDown}
        />
    );
};

export default LongVoxelPanel;
