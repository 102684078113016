// Copyright (C) 2024 Xtremis, All rights reserved

import { create } from "zustand";
import { API_URL, DIAGONAL_COMBOBOX, HISTORY_SIZE } from "./constants";

interface CacheStore {
    selectedAntenna: number;
    selectedAggregation: string;
    setSelectedAntenna: (antenna: number) => void;
    setAggregation: (aggregation: string) => void;
    error: string;
    setError: (error: string) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    heatmapData: HeatmapData | null;
    setHeatmapData: (heatmapData: HeatmapData | null) => void;
    updateHeatmapData: (data: Int8Array, receiver: number, feature: string) => void;
    showVoxels: boolean;
    setShowVoxels: (showVoxels: boolean) => void;
    tempHighlightBox: BoundingBox | null;
    setTempHighlightBox: (box: BoundingBox | null) => void;
    hoverCoords: Coords;
    setHoverCoords: (coords: Coords) => void;
    hoveredVoxelStats: VoxelStats | null;
    setHoveredVoxelStats: (stats: VoxelStats | null) => void;
    visibleArea: VisibleArea;
    setVisibleArea: (area: VisibleArea) => void;
    minDb: number;
    setMinDb: (minDb: number) => void;
    maxDb: number;
    setMaxDb: (maxDb: number) => void;

    minPaprDb: number;
    setMinPaprDb: (minPaprDb: number) => void;
    maxPaprDb: number;
    setPaprMaxDb: (maxPaprDb: number) => void;

    editingMode: boolean;
    setEditingMode: (mode: boolean) => void;

    canvasSize: CanvasSize; // use this only for drawing the hAxis, vAxis, LongVoxel and psd drawing
    setCanvasSize: (size: CanvasSize) => void;

    // Add scale and offset state for image from the main canvas
    scale: Scale;
    offset: Offset;
    scaleOffsetInitialized: boolean;
    setScale: (scale: Scale) => void;
    setOffset: (offset: Offset) => void;
    setScaleOffsetInitialized: (initialized: boolean) => void;

    nextBBoxId: number;
    getNextBoxId: () => number;
    voxels: BoundingBox[];
    meaVoxels: BoundingBox[];
    setMeaVoxels: (voxels: BoundingBox[]) => void;
    setSelectedMeaVoxel: (voxelId: number) => void;
    voxelHistory: BoundingBox[][];
    undo: () => void;
    persistVoxels: () => void;
    updateVoxel: (boundingBox: BoundingBox) => void;
    deleteSelectedVoxel: () => void;
    initVoxels: (boundingBoxes: BoundingBox[]) => void;
    deselectVoxels: () => void;
    voxelOpacity: boolean;
    setVoxelOpacity: (val: boolean) => void;

    pendingBox: BoundingBox | null;
    setPendingBox: (box: BoundingBox | null) => void;

    setVoxelSelected(id: number): void;

    reset: () => void;

    selectedPath: string;
    setSelectedPath: (path: string) => void;

    canvasMode: "DRAWING" | "LONG_VOXEL_DRAWING" | "EMPTY";
    setCanvasMode: (mode: "DRAWING" | "LONG_VOXEL_DRAWING" | "EMPTY") => void;

    measurements: Measurements;
    setMeasurements: (measurements: Measurements) => void;

    confidenceSliderValue: number;
    setConfidenceSliderValue: (value: number) => void;

    minColor: number;
    setMinColor: (color: number) => void;
    maxColor: number;
    setMaxColor: (color: number) => void;

    diagonalState: DIAGONAL_COMBOBOX;
    setDiagonalState: (diagonalState: DIAGONAL_COMBOBOX) => void;

    isCrossLineDrawActive: boolean;
    setCrossLineDrawActive: (isCrossLineDrawActive: boolean) => void;

    image: HTMLImageElement | null;
    setImage: (image: HTMLImageElement | null) => void;

    showMeasurements: boolean;
    setShowMeasurements: (showMeasurements: boolean) => void;
    selectedMeasurement: string,
    setSelectedMeasurement: (measurement: string) => void;
    isGTGenerated: boolean
    setIsGTGenerated: (isGTGenerated: boolean) => void;
}

export const useCacheStore = create<CacheStore>((set, get) => ({
    selectedAntenna: 0,
    selectedAggregation: "avg",
    error: "",
    loading: true,
    heatmapData: null,
    showVoxels: true,
    setSelectedAntenna: (antenna) => set({ selectedAntenna: antenna }),
    setAggregation: (aggregation) => set({ selectedAggregation: aggregation }),
    setError: (error) => set({ error }),
    setLoading: (loading) => set({ loading }),
    setHeatmapData: (heatmapData) => set({ heatmapData }),
    updateHeatmapData: (data: Int8Array, receiver: number, feature: string) => {
        const hData = get().heatmapData;
        if (!hData || !hData.images || !hData.images[receiver] || !(feature in hData.images[receiver])) {
            return;
        }
        hData.images[receiver][feature] = data;
        const max = hData.images[receiver]["max"];
        const avg = hData.images[receiver]["avg"];
        let minPaprdB = Number.POSITIVE_INFINITY;
        let maxPaprdB = Number.NEGATIVE_INFINITY;
        if (max && avg && max.length === avg.length) {
            const diffArray = new Int8Array(max.length);
            for (let j = 0; j < diffArray.length; j++) {
                diffArray[j] = max[j] - avg[j];
                // Update min and max while iterating
                if (diffArray[j] < minPaprdB) {
                    minPaprdB = diffArray[j];
                }
                if (diffArray[j] > maxPaprdB) {
                    maxPaprdB = diffArray[j];
                }
            }
            hData.images[receiver]["papr"] = diffArray;
        }
        set({ heatmapData: { ...hData } });
        set({ minPaprDb: minPaprdB });
        set({ maxPaprDb: maxPaprdB });
    },
    setShowVoxels: (showVoxels) => set({ showVoxels }),
    tempHighlightBox: null,
    setTempHighlightBox: (box) => set({ tempHighlightBox: box }),
    hoverCoords: { x: 0, y: 0 },
    setHoverCoords: (coords) => set({ hoverCoords: coords }),
    hoveredVoxelStats: null,
    setHoveredVoxelStats: (stats) => set({ hoveredVoxelStats: stats }),
    visibleArea: { fromRow: 0, toRow: 0, fromCol: 0, toCol: 0 },
    setVisibleArea: (area) => set({ visibleArea: area }),
    editingMode: false,
    setEditingMode: (mode: boolean) => set({ editingMode: mode }),
    minDb: -100,
    setMinDb: (minDb) => set({ minDb }),
    maxDb: 0,
    setMaxDb: (maxDb) => set({ maxDb }),

    minPaprDb: -100,
    setMinPaprDb: (minPaprDb) => set({ minPaprDb }),
    maxPaprDb: 0,
    setPaprMaxDb: (maxPaprDb) => set({ maxPaprDb }),

    canvasSize: { width: 0, height: 0 },
    setCanvasSize: (size: CanvasSize) => set({ canvasSize: size }),

    scale: { x: 1, y: 1 },
    offset: { x: 0, y: 0 },
    scaleOffsetInitialized: false,
    setScale: (scale: Scale) => set({ scale }),
    setOffset: (offset: Offset) => set({ offset }),
    setScaleOffsetInitialized: (initialized) => set({ scaleOffsetInitialized: initialized }),

    voxels: [],
    voxelHistory: [],
    undo: () => {
        const { voxelHistory } = get();
        if (voxelHistory.length > 1) {
            voxelHistory.pop();
            const actualVoxels = voxelHistory.pop();
            set({ voxels: actualVoxels });
            get().persistVoxels();
        } else {
            window.alert("No more history to undo");
        }
    },
    persistVoxels: () => {
        const voxels = get().voxels;
        const newHist = get().voxelHistory.slice(-HISTORY_SIZE);
        newHist.push(structuredClone(get().voxels));
        set({ voxelHistory: newHist });
        const height = get().heatmapData!.height;

        const zz = voxels.map((bb) => {
            const sr = bb.type === "longVoxel" ? 0 : height - Math.max(bb.start.row, bb.end.row);
            const er = bb.type === "longVoxel" ? height : height - Math.min(bb.start.row, bb.end.row);
            return {
                startRow: sr,
                endRow: er,
                startCol: Math.min(bb.start.col, bb.end.col),
                endCol: Math.max(bb.start.col, bb.end.col),
                modulation: bb.modulation,
                mod_variant: bb.mod_variant,
                emitter_id: bb.emitter_id,
                receive_power_dbm: bb.receive_power_dbm,
                confidence: bb.confidence,
                name: bb.name,
                subvoxels: bb.subvoxels,
                delay_spread: bb.delay_spread,
                delay_seed: bb.delay_seed,
                comment: bb.comment,
                emitter_type: bb.emitter_type
            };
        });

        fetch(`${API_URL}/update_voxels`, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                voxels: zz,
                path: get().selectedPath
            })
        })
        .then((response) => {
            if (response.ok === false || response.status !== 200) {
                get().undo();
                window.alert("Error updating voxels");
                return;
            }
            set({ voxels });
        })
        .catch((error: string) => {
            get().undo();
            console.error("Error updating voxels", error);
            window.alert("Error updating voxels");
        })
    },
    updateVoxel: (voxel) => {
        const voxels = get().voxels.map((v) => {
            return v.id === voxel.id ? voxel : v;
        });
        if (voxels.find((v) => v.id === voxel.id) === undefined) {
            voxels.push(voxel);
        }
        set({ voxels });
    },
    deleteSelectedVoxel: () => {
        const voxels = get().voxels.filter((voxel) => !voxel.selected);
        set({ voxels });
    },
    initVoxels: (voxels) => {
        set({ voxels });
        set({ voxelHistory: [structuredClone(voxels)] });
    },
    deselectVoxels: () => set({
        voxels: get().voxels.map((voxel) => ({ ...voxel, selected: false })),
        meaVoxels: get().meaVoxels.map((voxel) => ({ ...voxel, selected: false }))
    }),
    setVoxelSelected: (id) => {
        const voxels = get().voxels.map((voxel) => {
            if (voxel.id === id) {
                return { ...voxel, selected: true };
            }
            return { ...voxel, selected: false };
        });
        set({ voxels });
    },

    voxelOpacity: false, // default to fully opaque
    setVoxelOpacity: (val) => set(() => ({ voxelOpacity: val })),

    pendingBox: null,
    setPendingBox: (box: BoundingBox | null) => set({ pendingBox: box }),

    nextBBoxId: 0,
    getNextBoxId: () => {
        const newValue = get().nextBBoxId + 1;
        set({ nextBBoxId: newValue });
        return newValue;
    },

    selectedPath: "",
    setSelectedPath: (path) => set({ selectedPath: path }),
    canvasMode: "EMPTY",
    setCanvasMode: (canvasMode) => set({ canvasMode }),

    measurements: [],
    setMeasurements: (measurements: Measurements) => set({ measurements }),

    confidenceSliderValue: 0.5,
    setConfidenceSliderValue: (value) => set({ confidenceSliderValue: value }),

    reset: () =>
        set({
            selectedAntenna: 0,
            selectedAggregation: "avg",
            error: "",
            loading: true,
            heatmapData: null,
            showVoxels: true,
            hoverCoords: { x: 0, y: 0 },
            visibleArea: { fromRow: 0, toRow: 0, fromCol: 0, toCol: 0 },
            canvasSize: { width: 0, height: 0 },
            scale: { x: 1, y: 1 },
            offset: { x: 0, y: 0 },
            scaleOffsetInitialized: false,
            voxels: [],
            nextBBoxId: 0,
            editingMode: false,
            voxelHistory: [],
            selectedPath: "",
            canvasMode: "EMPTY",
            measurements: [],
            minColor: 0,
            maxColor: 1.0,
            diagonalState: "None",
            isCrossLineDrawActive: true,
            meaVoxels: [],
            image: null,
            showMeasurements: false,
            selectedMeasurement: "None",
            isGTGenerated: false
        }),
    minColor: 0,
    setMinColor: (color) => set({ minColor: color }),
    maxColor: 1,
    setMaxColor: (color) => set({ maxColor: color }),
    diagonalState: "None",
    setDiagonalState: (diagonalState) => set({ diagonalState }),
    isCrossLineDrawActive: true,
    setCrossLineDrawActive: (isCrossLineDrawActive: boolean) => set({ isCrossLineDrawActive }),
    image: null,
    setImage: (image: HTMLImageElement | null) => set({ image }),
    meaVoxels: [],
    setMeaVoxels: (voxels) => set({ meaVoxels: voxels }),
    setSelectedMeaVoxel: (voxelId: number) => {
        set({
            meaVoxels: get().meaVoxels.map(vox => vox.id === voxelId ? { ...vox, selected: true } : vox)
        });
    },
    showMeasurements: false,
    setShowMeasurements: (showMeasurements) => set({ showMeasurements }),
    selectedMeasurement: "None",
    setSelectedMeasurement: (measurement:string) => set({ selectedMeasurement: measurement }),
    isGTGenerated: false,
    setIsGTGenerated: (isGTGenerated) => set({ isGTGenerated })
}));
