// Copyright (C) 2024 Xtremis, All rights reserved

/**
 * The DataHandlingPanel component serves as the main container for visualizing and interacting with heatmap data. 
 * Displays a loading spinner while fetching data and handles errors gracefully.
 * Contains the following subcomponents 
 * - PSDDiagram
 * - LongVoxelPanel
 * - MainCanvas, 
 * - VerticalAxis
 * - HorizontalAxis
 * - Help Menu
 */

import React, { useState, useRef } from "react";
import MainCanvas from "./MainCanvas";
import PSDDiagram from "./PSDDiagram";
import VerticalAxis from "./VerticalAxis";
import HorizontalAxis from "./HorizontalAxis";
import LongVoxelPanel from "./LongVoxelPanel";
import HelpMenu from "./HelpMenu";
import { useCacheStore } from "../utils/store";
import "./DataHandlingPanel.css";

interface DataHandlingPanelProps {
    onLogout: () => void;
}

const DataHandlingPanel: React.FC<DataHandlingPanelProps> = ({ onLogout }) => {
    const selectedAntenna = useCacheStore(state => state.selectedAntenna);
    const selectedAggregation = useCacheStore(state => state.selectedAggregation);
    const loading = useCacheStore(state => state.loading);
    const error = useCacheStore(state => state.error);
    const heatmapData = useCacheStore(state => state.heatmapData);

    const dataHandlingPanelRef = useRef<HTMLDivElement>(null);

    const [showHelp, setShowHelp] = useState(false);

    if (loading) {
        return (
            <div className="data-handling-panel loading">
                <div className="spinner"></div>
            </div>
        );
    }
    if (error) {
        return <div className="data-handling-panel data-handling-message">{error}</div>;
    }
    return (
        <>
            {heatmapData && heatmapData.images[selectedAntenna][selectedAggregation] && (
                <div ref={dataHandlingPanelRef} className="data-handling-panel">
                    {/* Help Pictogram */}
                    <div id="info-icon" onClick={() => setShowHelp(!showHelp)} title="Help" />

                    {showHelp && <HelpMenu onClose={() => setShowHelp(false)} onLogout={onLogout} />}

                    <PSDDiagram />

                    <LongVoxelPanel />
                    <div className="long-voxel-panel">
                        <VerticalAxis />
                        <MainCanvas
                            imageData={heatmapData.images[selectedAntenna][selectedAggregation]!}
                            imageHeight={heatmapData.height}
                            imageWidth={heatmapData.width}
                        />
                        <HorizontalAxis />
                    </div>
                </div>
            )}
        </>
    );
};

export default DataHandlingPanel;
